import React from 'react';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';

import clientData from '../../inc/clientData';
import config from '../../inc/config';

import UpdateFavoriteSearchesModal from '../../components/UpdateFavoriteSearchesModal';
import { Link } from 'react-router-dom';
import Images from '../../utils/Images';

class FavoriteSearchesModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenUpdateFavoriteSearchesModal: false,

      searches: [],

      editSearch: null,
      searchId: 0,

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount() {
    this.getFavoriteSearches(this.state.currentPage);
    window.scrollTo(0, 0);
    console.log("FavoriteSearches: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("FavoriteSearches: componentWillUnmount()");
  }

  // getFavoriteSearches() {
  //   const requestOptions = {
  //     method: 'GET',
  //     headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
  //   };

  //   fetch(config.restApi + 'favoriteSearches/tr', requestOptions)
  //   .then(response => response.json())
  //   .then(responseData => responseData.status === 200 ? this.setState({isLoading: false, searches: responseData.searches}) : swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => this.props.navigate('/')), () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getFavoriteSearches()));
  // }

  getFavoriteSearches(currentPage) {
    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
  };

  const startIndex = 0;
  const endIndex = startIndex + this.state.limit;

  this.setState({isLoading: true})

  fetch(config.restApi + `favoriteSearches/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const currentSearches = responseData.searches.slice(startIndex, endIndex);
          this.setState({isLoading: false, searches: currentSearches, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteFavoriteSearchOnClick(id){
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Aramayı favorilerinizden çıkarmak istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
        };

        fetch(config.restApi + 'favoriteSearch/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Arama favorilerden başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getFavoriteSearches());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  updateFavoriteSearchOnClick(title) {
    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({title: title})
    };

    fetch(config.restApi + 'favoriteSearch/tr/' + this.state.searchId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Arama adı başarıyla güncellenmiştir.',
            buttons: 'Tamam'
          }).then(() => this.setState({isOpenUpdateFavoriteSearchesModal: false}), this.getFavoriteSearches());
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getFavoriteSearches(this.state.currentPage));
    window.scrollTo(0, 0);
  }

  getSearchLink(params) {
    let filter = [];

    params.categoryId && filter.push('categoryId=' + params.categoryId);
    params.addedBy && filter.push('addedBy=' + params.addedBy);
    params.memberId && filter.push('memberId=' + params.memberId);

    if (Array.isArray(params.cityIds)) {
      params.cityIds.length > 0 && filter.push('cityIds=' + params.cityIds.join(','));
    } else {
      params.cityIds && filter.push('cityIds=' + params.cityIds);
    }
    if (Array.isArray(params.cityIds)) {
      params.townIds.length > 0 && filter.push('townIds=' + params.townIds.join(','));
    } else {
      params.townIds && filter.push('townIds=' + params.townIds);
    }
    if (Array.isArray(params.cityIds)) {
      params.quarterIds.length > 0 && filter.push('quarterIds=' + params.quarterIds.join(','));
    } else {
      params.quarterIds && filter.push('quarterIds=' + params.quarterIds);
    }

    if (params.maxPrice || params.minPrice) {
      filter.push('currencyPrice=' + params.currencyPrice)
    }

    params.maxPrice && filter.push('maxPrice=' + params.maxPrice);
    params.minPrice && filter.push('minPrice=' + params.minPrice);

    params.lastUpdate && filter.push('lastUpdate=' + params.lastUpdate);
    params.hasPhoto && filter.push('hasPhoto=1');

    if (params.searchText) {
      filter.push('searchText=' + params.searchText);
      filter.push('searchType=' + params.searchType);
    }

    params.attributes?.length > 0 && filter.push('attributes=' + params.attributes.join(','));

    let fields = params.fields && Object.entries(params.fields).map(([fieldId, values]) => `${fieldId}:${values.join(',')}`).join(';');
    fields && filter.push('fields=' + fields);

    let rangeFields = params.rangeFields && Object.entries(params.rangeFields).map(([fieldId, values]) => `${fieldId}:${values.join('-')}`).join(';');
    rangeFields && filter.push('rangeFields=' + rangeFields);

    params.sort && filter.push('sort=' + params.sort);

    if (filter.length > 0) {
      return `https://www.${config.host}/adverts?${filter.join('&')}`;
    } else {
      return `https://www.${config.host}/adverts`;
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col">
            <h3 className="text-darkblue">Favori Aramalarım</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div>
          <Link className="btn btn-none-xs border btn-darkgray bg-orange-btn text-black mr-1" to={"/favoriteAdverts"}>Favori İlanlarım</Link>
          <Link className="btn btn-none-xs border btn-darkgray bg-orange text-white mr-1" to={"/favoriteSearches"}>Favori Aramalarım</Link>
          <Link className="btn btn-none-xs border btn-darkgray bg-orange-btn text-black" to={"/favoriteSellers"}>Favori Satıcılarım</Link>
        </div>
        <div className="d-flex justify-content-end">
          <div className="btn-open-xs btn border border-darkblue text-center mb-1 ">
            <span className="dropdown-toggle font-bold text-darkblue" data-toggle="dropdown"><i className="fa fa-ellipsis-v text-darkblue"></i></span>
            <ul className="dropdown-menu overflow-auto mr-3 mt-2">
              <li><Link className="dropdown-item font-bold" to={"/favoriteAdverts"}> Favori İlanlarım</Link></li>
              <li><Link className="dropdown-item font-bold" to={"/favoriteSearches"}> Favori Aramalarım</Link></li>
              <li><Link className="dropdown-item font-bold" to={"/favoriteSellers"}> Favori Satıcılarım</Link></li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col my-3">
            <div className="table-responsive-lg mb-4">
              <table className="table table-bordered text-center">
                {this.state.searches.length !== 0 &&
                  <thead>
                    <tr className="text-darkblue">
                      <th>Arama Adı</th>
                      <th>Günlük Bildirimler</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                }
                <tbody>
                  {this.state.searches.map((searchInfo) =>
                    <tr key={searchInfo.id}>  
                      <td className="align-middle"><a className="text-orange text-underline-orange" target='_blank' href={this.getSearchLink(JSON.parse(searchInfo.params || '{}'))}>{searchInfo.title}</a></td>
                      <td className="align-middle">Bildirimler Kapalı</td>
                      <td className="align-middle" style={{minWidth: 98.5}}>
                        <button className="btn border border-orange m-1" title="Düzenle" onClick={() => this.setState({editSearch: searchInfo, isOpenUpdateFavoriteSearchesModal: true, searchId: searchInfo.id})}><i className="fa fa-pencil text-orange" ></i></button>
                        <button className="btn border border-darkblue text-center" title="Sil" onClick={() => this.deleteFavoriteSearchOnClick(searchInfo.id)}><i className="fa fa-trash text-darkblue"></i></button>
                      </td>
                    </tr>
                  )}
                  {this.state.searches.length === 0 &&
                    <div className="col-12 d-block notFoundImg">
                      <div className="text-center">
                        <img className="mb-4 mt-0" src={Images.favoriteSearchNotFound}/>
                      </div>
                      <div className="text-center my-auto">
                        <h2 className="mb-1 mt-2"><b className="text-orange">Favori Aramanızda İlan Bulunamadı!</b></h2>
                        <h2 className="m-0 pb-4 text-darkblue">İlgilendiğiniz aramayı favorilerinize eklemek istiyorsanız,arama sayfasındaki "Aramayı Kaydet" butonuna tıklayınız.</h2>
                      </div>
                    </div>
                  }
                </tbody>
              </table>
              {this.state.editSearch && <UpdateFavoriteSearchesModal defaultValue={this.state.editSearch} isOpen={this.state.isOpenUpdateFavoriteSearchesModal} modalTitle="Arama Düzenle" modalDescription="Arama adını düzenlemek aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editSearch: null, isOpenUpdateFavoriteSearchesModal: false})} saveOnClick={title => this.updateFavoriteSearchOnClick(title)} />}
            </div>
            {this.state.searches.length !== 0 &&
            <ReactPaginate
              previousLabel={"Geri"}
              nextLabel={"İleri"}
              breakLabel={"..."}  
              pageCount={this.state.pageCount}
              marginPagesDisplayed={1}
              onPageChange={page => this.handlePageClick(page)}
              pageRangeDisplayed={2}
              containerClassName={'pagination'}
              activeClassName={'activePage'}
              forcePage={this.state.currentPage - 1}
            />
            }
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="p-2 text-darkblue">
             * E-posta ile bildirimi tercih ettiğinizde, arama kriterlerinize uygun ilanlar 90 gün boyunca günlük olarak <strong className="text-underline-darkblue">{clientData.email}</strong> adresinize, mobil bildirimi tercih ettiğinizde ise uygun ilanlar uygulamanın yüklü ve hesabınızın tanımlı olduğu cep telefonuna 90 gün boyunca mobil bildirim olarak gönderilecektir.
            </div>
          </div>
        </div> 
      </div>
  }
}

export default function FavoriteSearches() {
  return (
    <FavoriteSearchesModule />
  )
}