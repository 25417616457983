import React from 'react';
import swal from 'sweetalert';
import {Link} from 'react-router-dom';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import Images from '../../utils/Images';
import IdentityValidationSuccess from './IdentityValidationSuccess';

class IdentityValidationModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      email: 0,
      identity: 0,
      phone:0
    };
  }

  componentDidMount() {
    this.getValidationStatus()
    window.scrollTo(0, 0);
    console.log("Consultants: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("Consultants: componentWillUnmount()");
  }

  getValidationStatus() {
    
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    this.setState({isLoading: true});

    fetch(config.restApi + 'validationStatus', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          this.setState({isLoading: false, email: responseData.email, identity: responseData.identity, phone: responseData.phone});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet basadadğlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        {this.state.identity === 0 ?
        <div className="mt-2">
          <div className="row">
            <div className="col d-flex justify-content-between">
              <h3 className="text-darkblue">e-Devlet</h3>
            </div>
          </div>
          <div className="hr-line-dashed mt-0"></div>
          <div className="row">
            <div className="col my-2">
              <div className="table-responsive-lg">
                  <h4 className="text-darkblue col-md-6 p-0">İkinci el araç ve emlak alım-satım ve kiralamasında güvenirliliği artırmak amacıyla Ticaret Bakanlığı'nın yayınladığı yönetmelik gereği, ilanınızı yayınlayabilmek için <b>bir defaya mahsus</b> olarak e-Devlet ile hesabınızı doğrulamanız gerekiyor.</h4>
                  <Link className="btn bg-orange text-white my-2" to={'https://eids.ticaret.gov.tr/oturum?firmaKodu=1C8004D9-59E7-40AA-9DAA-084CBFB68647'} title="e-Devlet ile Doğrula"><i className="fa fa-id-card mr-1"></i>e-Devlet ile Doğrula</Link>
                  <div>
                    <img className="mb-4 mt-0" src={Images.identityValidation}/>
                  </div>
              </div>
            </div>
          </div>
        </div>
        :
        <IdentityValidationSuccess/>
        }
      </>
  }
}

export default function IdentityValidation() {
  return (
    <IdentityValidationModule />
  )
}