import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import {isoToDate, priceFormat, slugify} from '../../inc/helper';
import Images from '../../utils/Images';

class ConsultantAdvertModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      adverts: [],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 5
    };
  }

  componentDidMount() {
    this.getAdverts(this.state.currentPage);
    window.scrollTo(0, 0);
    console.log("ConsultantAdverts: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("ConsultantAdverts: componentWillUnmount()");
  }

  getAdverts(currentPage) {
    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
  };

  const startIndex = 0;
  const endIndex = startIndex + this.state.limit;
  
  this.setState({isLoading: true})
  
  fetch(config.restApi + `myAdverts/tr?limit=${this.state.limit}&page=${currentPage}&` + 'filterMemberId=' + clientData.id + '&filterAddedBy=' + this.props.searchParams.get('consultantId'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const currentAdverts = responseData.adverts.slice(startIndex, endIndex);
          this.setState({isLoading: false, adverts: currentAdverts, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getAdvertStatus(id) {
    if(id === 1) {
      return <strong className="text-warning">Onay Bekliyor</strong>
    } else if(id === 2) {
      return <strong className="text-green">Yayında</strong>
    } else if(id === 3) {
      return <strong className="text-orange">Reddedildi</strong>
    } else if(id === 4) {
      return <strong className="text-darkblue">Pasif</strong>
    } else {
      return '-'
    }
  }

  
  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getAdverts(this.state.currentPage));
    window.scrollTo(0, 0);
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <h3 className="text-darkblue">Danışmana Ait İlan Listesi</h3>
            <button className="btn border border-darkblue text-darkblue mb-2" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row">
          <div className="col my-2">
            <div className="table-responsive-lg">
              <table className="table table-bordered text-center">
                {this.state.adverts.length !== 0 &&
                  <thead>
                    <tr className="text-darkblue">
                      <th>İlan Görseli</th>
                      <th>İlan Adı</th>
                      <th>İlan Fiyatı</th>
                      <th>İlan Tarihi</th>
                      <th>Bölge</th>
                      <th>İlan Durumu</th>
                    </tr>
                  </thead>
                }
                <tbody>
                  {this.state.adverts.map((advertInfo) =>
                    <tr key={advertInfo.id}>  
                      <td className="align-middle">
                        {advertInfo.photos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${advertInfo.photos[0].path}`}/>
                        : <i className="fa fa-camera d-flex align-items-center justify-content-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 80, color: '#d2d2d2'}}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{advertInfo.id}</strong>
                      </td>
                      <td className="align-middle">{advertInfo.title ? < Link target='_blank' to={`https://${config.host}/ilan/${slugify(advertInfo.title)}-${advertInfo.id}/detay`}>{advertInfo.title}</Link> : <Link>İlan başlığı girilmedi</Link>}<br />
                        <div className="mt-3 d-block d-lg-flex justify-content-center">
                          <div><span className="mr-2 pointer" title="Favoriler"><i className="fa fa-heart text-danger"></i> {advertInfo.favoriteCount}</span></div>
                          <div><span className="pointer" title="Görüntüleme"><i className="fa fa-eye text-green"></i> {advertInfo.views}</span></div>
                        </div>
                      </td>
                      <td className="align-middle">{priceFormat(advertInfo.price)} {advertInfo.currencySymbol}</td>
                      <td className="align-middle">{isoToDate(advertInfo.addedDate)}</td>
                      <td className="align-middle">{advertInfo.townName + ' / ' + advertInfo.cityName}</td>
                      <td className="align-middle">{this.getAdvertStatus(advertInfo.advertStatus)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {this.state.adverts.length === 0 &&
                <div className="col-12 d-block notFoundImg">
                  <div className="text-center">
                    <img className="m-4" src={Images.advertNotFound}/>
                  </div>
                  <div className="text-center my-auto">
                    <h2 className="mb-1 mt-2"><b className="text-orange">Danışman İlanı Bulunamadı!</b></h2>
                    <h2 className="m-0 pb-4 text-darkblue">Şu anda danışmana ait ilan bulunmamaktadır.</h2>
                  </div>
                </div>
              }
            </div>
            {this.state.adverts.length !== 0 &&
              <ReactPaginate
                previousLabel={"Geri"}
                nextLabel={"İleri"}
                breakLabel={"..."}  
                pageCount={this.state.pageCount}
                marginPagesDisplayed={1}
                onPageChange={page => this.handlePageClick(page)}
                pageRangeDisplayed={2}
                containerClassName={'pagination'}
                activeClassName={'activePage'}
                forcePage={this.state.currentPage - 1}
              />
            }
          </div>
        </div>
      </div>
  }
}

export default function ConsultantAdverts() {
  return (
    <ConsultantAdvertModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}
