import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import GoogleMapReact from 'google-map-react';

import AdvertLanguageAddModal from '../../components/AdvertLanguageAddModal';
import AssignConsultantModal from '../../components/AssignConsultantModal';
import {CustomWidgetPrepare} from '../../components/CustomWidget';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import swal from 'sweetalert';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import Images from '../../utils/Images';
import AdvertRotationModal from '../../components/AdvertRotationModal';
import {isoToDateTime} from '../../inc/helper';

const Marker = () => (<i className="fa fa-map-marker fa-5x text-danger ml-n3 mt-n5"></i>);

class AdvertEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      uploadLoading: false,
      isOpenAdvertLangugeAddModal: false,
      isOpenAssignConsultantModal: false,

      advertAttributes: [],
      advertFields: [],
      advertLanguages: [],
      consultants: [],
      datas: {},
      files: [],
      advertPhotos: [],
      advertFiles: [],
      categoryPath: [],

      countryId: 1,
      countryName: 'Türkiye',
      cityId: 0,
      cityName: '',
      townId: 0,
      townName: '',
      districtId: 0,
      districtName: '',
      quarterId: 0,
      quarterName: '',

      lat: 39.886559,
      lng: 32.855350,
      zoom: 15,

      countries: [],
      cities: [],
      towns: [],
      regions: [],
      quarters: [],

      selectedLanguageIndex: -1,
      content: '',
      isOpenRotationModal: false,
      photoId: 0,
      photoPath: ''
    };
    this.scrollRef = React.createRef();
    this.scrollRefFile = React.createRef();
    this.onAdvertLanguageAdded = this.onAdvertLanguageAdded.bind(this);
    this.onRotationCompleted = this.onRotationCompleted.bind(this);
  }

  componentDidMount() {
    this.getAdvert();
    window.scrollTo(0, 0);
    console.log("ActveAdverts: componentDidMount()");
  }

  componentDidUpdate(prevProps, prevState) {
    let newTabIndex = parseInt(this.props.searchParams.get('tab')) || 0;
    if(prevState.selectedTabIndex !== newTabIndex) {
      this.setState({selectedTabIndex: newTabIndex});
    }
    if (this.state.uploadLoading === true) {
      window.scrollTo(0, 0); 
    }
  }

  componentWillUnmount() {
    console.log("ActveAdverts: componentWillUnmount()");
  }

  getAdvert() {
    let sessionHash = getCookie('sessionHash');
    let responseAdvert;
    let responseConsultant;
    
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false, ...responseAdvert, ...responseConsultant}, () => this.getLocation());
        if (errorMessages.length > 0) {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: errorMessages[0],
            buttons: 'Tamam'
          });
        }
      }
    }

    fetch(config.restApi + 'advertEdit/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const blocksFromHTML = convertFromHTML(responseData.advertLanguages.find(item => item.language === 'tr').content)
          const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
          
          responseAdvert =
          {
            advertAttributes: responseData.advertAttributes,
            advertFields: responseData.advertFields,
            advertLanguages: responseData.advertLanguages,
            advertPhotos: responseData.advertPhotos,
            advertFiles: responseData.advertFiles,
            categoryPath: responseData.categoryPath,

            lat: responseData.datas.lat,
            lng: responseData.datas.lon,

            countryId: responseData.datas.countryId,
            cityId: responseData.datas.cityId,
            townId: responseData.datas.townId,
            districtId: responseData.datas.districtId,
            quarterId: responseData.datas.quarterId,

            datas: responseData.datas,
            selectedLanguageIndex: responseData.advertLanguages.findIndex(item => item.language === 'tr'),
            content: EditorState.createWithContent(content)
          }
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'consultants/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {          
          responseConsultant =
          {consultants: responseData.consultants}
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getLocation() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 1;

    if (this.state.countryId) remaining++;
    if (this.state.cityId) remaining++;
    if (this.state.townId) remaining++;

    this.setState({isLoading: true});

    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + 'countries/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({countries: responseData.countries});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    if (this.state.countryId) {
      fetch(config.restApi + 'cities/tr/' + this.state.countryId + '?filterActive=1', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({cities: responseData.cities});
          }
          break;
          default: {
            errorMessages.push(responseData.message);
          }
          break;
        }
        complete();
      }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
    }

    if (this.state.cityId) {
      fetch(config.restApi + 'towns/tr/' + this.state.cityId + '?filterActive=1', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({towns: responseData.towns});
          }
          break;
          default: {
            errorMessages.push(responseData.message);
          }
          break;
        }
        complete();
      }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
    }

    if (this.state.townId) {
      fetch(config.restApi + 'regions/tr/' + this.state.townId + '?filterActive=1', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({regions: responseData.regions, quarters: responseData.regions.filter(districtInfo => districtInfo.id === this.state.districtId)[0].quarters});
          }
          break;
          default: {
            errorMessages.push(responseData.message);
          }
          break;
        }
        complete();
      }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
    }
  }

  getAdvertForFile() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    this.setState({isLoading: true});

    fetch(config.restApi + 'advertEdit/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading:false,advertPhotos: responseData.advertPhotos, advertFiles: responseData.advertFiles});
          if (this.scrollRef.current) {
            this.scrollRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getAdvertForFiles() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    this.setState({isLoading: true});

    fetch(config.restApi + 'advertEdit/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading:false, advertFiles: responseData.advertFiles });
          if (this.scrollRefFile.current) {
            this.scrollRefFile.current.scrollIntoView({ behavior: 'smooth' });
          }
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getAdvertLanguages() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    this.setState({isLoading: true});

    fetch(config.restApi + 'advertEdit/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading:false,advertLanguages: responseData.advertLanguages});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  //OK
  countryOnChange = (event) => {
    let countryId = event.target.value;
    const selectedCountry = this.state.countries.find((country) => country.id === parseInt(countryId));
    if (countryId) {
      this.getCities(countryId);
      this.setState({countryId: parseInt(countryId), countryName: event.target.options[event.target.selectedIndex].text, lat: selectedCountry.lat, lng: selectedCountry.lon, zoom: selectedCountry.zoom, cityId: '', cityName: '', townId: '', townName: '', districtId: '', districtName: '', quarterId: '', quarterName: '', cities: [], towns: [], regions: [], quarters: []});
    } else {
      this.setState({countryId: '', countryName: '', cityId: '', cityName: '', townId: '', townName: '', districtId: '', districtName: '', quarterId: '', quarterName: '', cities: [], towns: [], regions: [], quarters: []});
    }
  }

  //OK
  cityOnChange = (event) => {
    let cityId = event.target.value;
    const selectedCity = this.state.cities.find((city) => city.id === parseInt(cityId));
    if (cityId) {
      this.getTowns(cityId);
      this.setState({cityId: parseInt(cityId), cityName: event.target.options[event.target.selectedIndex].text, lat: selectedCity.lat, lng: selectedCity.lon, zoom: selectedCity.zoom, townId: '', townName: '', districtId: '', districtName: '', quarterId: '', quarterName: '', towns: [], regions: [], quarters: []});
    } else {
      this.setState({cityId: '', cityName: '', townId: '', townName: '', districtId: '', districtName: '', quarterId: '', quarterName: '', towns: [], regions: [], quarters: []});
    }
  }

  //OK
  townOnChange = (event) => {
    let townId = event.target.value;
    const selectedTown = this.state.towns.find((town) => town.id === parseInt(townId));
    if (townId) {
      this.getRegions(townId);
      this.setState({townId: parseInt(townId), townName: event.target.options[event.target.selectedIndex].text, lat: selectedTown.lat !== 0 ? selectedTown.lat : this.state.cityId.lat, lng: selectedTown.lon !== 0 ? selectedTown.lon : this.state.cityId.lon, zoom: selectedTown.zoom, districtId: '', districtName: '', quarterId: '', quarterName: '', regions: [], quarters: []});
    } else {
      this.setState({townId: '', townName: '', districtId: '', districtName: '', quarterId: '', quarterName: '', regions: [], quarters: []});
    }
  }

  districtOnChange = (event) => {
    let districtId = event.target.value;
    const selectedDistrict = this.state.regions.find((region) => region.id === parseInt(districtId));
    if (districtId) {
      this.setState({districtId: parseInt(districtId), districtName: event.target.options[event.target.selectedIndex].text, lat: selectedDistrict.lat !== 0 ? selectedDistrict.lat : this.state.townId.lat, lng: selectedDistrict.lon !== 0 ? selectedDistrict.lon : this.state.townId.lon, zoom: selectedDistrict.zoom, quarterId: '', quarterName: '', quarters: this.state.regions.filter(districtInfo => districtInfo.id === parseInt(districtId))[0].quarters});
    } else {
      this.setState({districtId: '', districtName: '', quarterId: '', quarterName: '', quarters: []});
    }
  }

  quarterOnChange = (event) => {
    let quarterId = event.target.value;
    const selectedQuarter = this.state.quarters.find((quarter) => quarter.id === parseInt(quarterId));
    const selectedTown = this.state.towns.find((town) => town.id === parseInt(this.state.townId));
    const selectedCity = this.state.cities.find((city) => city.id === parseInt(this.state.cityId));
    if (quarterId) {
      this.setState({quarterId: parseInt(quarterId), quarterName: event.target.options[event.target.selectedIndex].text, lat: selectedQuarter.lat || selectedTown.lat || selectedCity.lat, lng: selectedQuarter.lon || selectedTown.lon || selectedCity.lon, zoom: selectedQuarter.zoom,});
    } else {
      this.setState({quarterId: '', quarterName: ''});
    }
  }

  getCities(countryId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'cities/tr/' + countryId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({cities: responseData.cities, towns: [], regions: []});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }
  
  //OK
  getTowns(cityId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'towns/tr/' + cityId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({towns: responseData.towns, regions: []});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getRegions(townId) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'regions/tr/' + townId + '?filterActive=1', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({regions: responseData.regions});
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  titleOnChange = (event) => {
    const advertLanguages = this.state.advertLanguages;
    advertLanguages[this.state.selectedLanguageIndex].title = event.target.value;
    this.setState({advertLanguages: advertLanguages})
  }

  descriptionOnChange = (event) => {
    const advertLanguages = this.state.advertLanguages;
    advertLanguages[this.state.selectedLanguageIndex].description = event.target.value;
    this.setState({advertLanguages: advertLanguages})
  }

  commentOnChange = (event) => {
    const comment = this.state.datas;
    comment.comment = event.target.value;
    this.setState({datas: comment})
  }

  contentOnChange = (event) => {
    const selectedLanguageIndex = event.target.selectedIndex;
    const content = this.state.advertLanguages[selectedLanguageIndex].content;
    const blocksFromHTML = convertFromHTML(content);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    const editorState = EditorState.createWithContent(contentState);
    this.setState({selectedLanguageIndex, content: editorState});
  }

  deleteAdvertLanguageOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'İlan çevirisini silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash}
        };

        fetch(config.restApi + 'advertLanguage/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'İlan çevirisi başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.setState({selectedLanguageIndex: 0}, () => this.onAdvertLanguageDelete(id)));
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  onAdvertLanguageAdded(languageInfo) {
    let currentLanguages = this.state.advertLanguages;
    currentLanguages.push(languageInfo);
    this.setState({advertLanguages: currentLanguages, isOpenAdvertLangugeAddModal: false});
  }

  onAdvertLanguageDelete(id) {
    let currentLanguages = this.state.advertLanguages;
    let updatedLanguages = currentLanguages.filter(language => language.id !== id);
    this.setState({ advertLanguages: updatedLanguages });
  } 

  onRotationCompleted() {
    this.setState({isLoading: true}, () => {this.getAdvertForFile(); this.setState({files: ''});this.setState({isLoading: false});});
    this.setState({isOpenRotationModal: false})
  }

  saveAdvertOnClick = () => {
     let sessionHash = getCookie('sessionHash');

     const contentState = draftToHtml(convertToRaw(this.state.content.getCurrentContent()));

     let datas = {...this.state.datas, countryId: this.state.countryId, cityId: this.state.cityId, townId: this.state.townId, quarterId: this.state.quarterId, districtId: this.state.districtId, lat: this.state.lat, lon: this.state.lng, advertLanguages: this.state.advertLanguages, advertPhotos: this.state.advertPhotos, advertFiles: this.state.advertFiles};

     datas.advertLanguages[this.state.selectedLanguageIndex].content = contentState;

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify(datas)
      };

      fetch(config.restApi + 'advertEdit/tr/' + this.props.searchParams.get('id') + '?step=6', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});
        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'İlanınız başarıyla onaya gönderildi.',
              buttons: 'Tamam'
            }).then(() => this.props.navigate('/adverts'));
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  saveAssignConsultantOnClick = (consultantInfo) => {

    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'advertConsultant/tr/' + this.props.searchParams.get('id') + '?consultantId=' + consultantInfo.id,  requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Başarılı',
            text: 'Danışman ilana başarıyla atandı.',
            buttons: 'Tamam'
          }).then(() => this.setState({isOpenAssignConsultantModal: false}))
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveAdvertOnClick();
    }
  }

  deleteAdvertPhotoOnClick(uploadId) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'İlan resmini silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash},
        };

        fetch(config.restApi + 'advertPhotoUpload/tr/' + uploadId, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'İlan resmi başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.setState({isLoading: true}, () => {this.getAdvertForFile();this.setState({isLoading: false});}));
            }
            break;
            case 401:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              }).then(() => this.setState({isLoading: true}, () => {this.getAdvertForFile(); this.setState({files: ''});this.setState({isLoading: false});}));
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              }).then(() => this.setState({isLoading: true}, () => {this.getAdvertForFile(); this.setState({files: ''});this.setState({isLoading: false});}));
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  saveFileUpload = (index = 0) => {
    let sessionHash = getCookie('sessionHash');
    const formData = new FormData();
  
    for (let i = 0; i < this.state.files.length; i++) {
      formData.append('files', this.state.files[i]);
    }
  
    const fileCount = formData.getAll('files').length;
    const singleFileData = new FormData();
    singleFileData.append('files', formData.getAll('files')[index]);
  
    const requestOptions = {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + sessionHash },
      body: singleFileData,
    };
  
    this.setState({ uploadLoading: true });
  
    fetch(config.restApi + 'advertPhotoUpload/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then((response) => response.json())
      .then(responseData => {
        const progressPercentage = ((index + 1) / fileCount) * 100;
        document.querySelector('.progress-bar').style.width = `${progressPercentage}%`;
        document.querySelector('.progress-bar').innerText = `%${Math.round(progressPercentage)} Tamamlandı`;
  
        switch (responseData.status) {
          case 200:
            if (index === fileCount - 1) {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Başarılı',
                text: 'Tüm fotoğraflar ilana başarıyla eklendi.',
                buttons: 'Tamam'
              }).then(() => {
                this.setState({ isLoading: true }, () => {
                  this.getAdvertForFile();
                  this.setState({ files: '' });
                  this.setState({ isLoading: false, uploadLoading: false });
                });
              });
            } else {
              this.saveFileUpload(index + 1);
            }
            break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => {
              this.getAdvertForFile();
              this.setState({ uploadLoading: false });
            });
            break;
        }
      })
      .catch(() =>
        swal({
          dangerMode: true,
          icon: 'error',
          title: 'Bağlantı Hatası',
          text: 'Lütfen internet bağlantınızı kontrol ediniz.',
          buttons: 'Tamam',
        }).then(() => this.setState({ uploadLoading: false })));
  };

  deleteAdvertFileOnClick(uploadId) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'İlan evrağını silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + sessionHash},
        };

        fetch(config.restApi + 'advertFileUpload/tr/' + uploadId, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Evrak başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.setState({isLoading: true}, () => {this.getAdvertForFiles();this.setState({isLoading: false});}));
            }
            break;
            case 401:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              }).then(() => this.setState({isLoading: true}, () => {this.getAdvertForFiles(); this.setState({files: ''});this.setState({isLoading: false});}));
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              }).then(() => this.setState({isLoading: true}, () => {this.getAdvertForFiles(); this.setState({files: ''});this.setState({isLoading: false});}));
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  saveAdvertFileUpload = (index = 0) => {
    let sessionHash = getCookie('sessionHash');
    const formData = new FormData();
  
    for (let i = 0; i < this.state.files.length; i++) {
      formData.append('files', this.state.files[i]);
    }
  
    const fileCount = formData.getAll('files').length;
    const singleFileData = new FormData();
    singleFileData.append('files', formData.getAll('files')[index]);
  
    const requestOptions = {
      method: 'POST',
      headers: { 'Authorization': 'Bearer ' + sessionHash },
      body: singleFileData,
    };
  
    this.setState({ uploadLoading: true });
  
    fetch(config.restApi + 'advertFileUpload/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then((response) => response.json())
      .then(responseData => {
        const progressPercentage = ((index + 1) / fileCount) * 100;
        document.querySelector('.progress-bar').style.width = `${progressPercentage}%`;
        document.querySelector('.progress-bar').innerText = `%${Math.round(progressPercentage)} Tamamlandı`;
  
        switch (responseData.status) {
          case 200:
            if (index === fileCount - 1) {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Başarılı',
                text: 'Evrak ilana başarıyla eklendi.',
                buttons: 'Tamam'
              }).then(() => {this.setState({ isLoading: true }, () => {this.getAdvertForFiles(); this.setState({ files: '' }); this.setState({ isLoading: false, uploadLoading: false });});});
            } else {
              this.saveAdvertFileUpload(index + 1);
            }
            break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => {
              this.getAdvertForFiles();
              this.setState({ uploadLoading: false });
            });
            break;
        }
      })
      .catch(() =>
        swal({
          dangerMode: true,
          icon: 'error',
          title: 'Bağlantı Hatası',
          text: 'Lütfen internet bağlantınızı kontrol ediniz.',
          buttons: 'Tamam',
        }).then(() => this.setState({ uploadLoading: false })));
  };

/*   saveAdvertFileUpload = () => {
    let sessionHash = getCookie('sessionHash');
  
    const formData = new FormData();

    for (let i = 0; i < this.state.files.length; i++) {
      formData.append('files', this.state.files[i]);
    }

    const fileCount = formData.getAll('files').length;
    
    let uploadedFilesCount = 0;
    let totalFiles = fileCount;
    
    for (let i = 0; i < fileCount; i++) {
      const singleFileData = new FormData();
      singleFileData.append('files', formData.getAll('files')[i]);
    
      const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + sessionHash },
        body: singleFileData,
      };
  
      this.setState({uploadLoading: true});
  
      fetch(config.restApi + 'advertFileUpload/tr/' + this.props.searchParams.get('id'), requestOptions)
        .then((response) => response.json())
        .then(responseData => {

        uploadedFilesCount++;
        const progressPercentage = (uploadedFilesCount / totalFiles) * 100;
        document.querySelector('.progress-bar').style.width = `${progressPercentage}%`;
        document.querySelector('.progress-bar').innerText = `%${Math.round(progressPercentage)} Tamamlandı`;

        switch (responseData.status) {
          case 200: {
            progressPercentage === 100 &&
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Başarılı',
              text: 'Evrak ilana başarıyla eklendi.',
              buttons: 'Tamam'
            }).then(() => this.setState({isLoading: true}, () => {this.getAdvertForFiles(); this.setState({files: ''});this.setState({isLoading: false, uploadLoading:false});}));
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(this.getAdvertForFiles(), this.setState({uploadLoading: false}));
          }
          break;
        }
      })
      .catch(() =>
        swal({
          dangerMode: true,
          icon: 'error',
          title: 'Bağlantı Hatası',
          text: 'Lütfen internet bağlantınızı kontrol ediniz.',
          buttons: 'Tamam',
        }).then(this.setState({uploadLoading: false}))
      );
    }
  }; */

  handleEdit = (photoId, photoPath) => {
    this.setState({isOpenRotationModal: true, photoId: photoId, photoPath: photoPath})
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({ advertPhotos: arrayMove(this.state.advertPhotos, oldIndex, newIndex) });
  };

  render() {
    const SortableItem = SortableElement(({ value }) => <div style={{ position: "relative"}}>{value}</div>);
    
    const SortableList = SortableContainer(({ items }) => (
      <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
        {items.map((photo, index) => (
          <SortableItem key={`item-${index}`} index={index} value={
            <div style={{ position: "relative", display: "inline-block", border:"1px solid lightgray", backgroundColor: "#F5F5F5"}}>
              <img className='col-12 p-0' style={{height:300, width:533, objectFit:"contain", overflow: "visible", display: "block"}} src={`https://file.satisgaranti.com/${photo.path}`}></img>
              <img style={{position: "absolute", top: 0, left: 0}} src={items[0].path === photo.path  ? Images.showCase : ''}/>
              <button className="btn btn-default btn-circle" title='Sil' onClick={() => this.deleteAdvertPhotoOnClick(photo.id)} style={{ position: "absolute", top: 0, right: 0}}><i className="fa fa-close" style={{pointerEvents: 'none', color:"black"}}></i></button>
              <button className="btn btn-default btn-circle" onClick={() => this.handleEdit(photo.id, photo.path)} style={{ position: "absolute", bottom: 0, right: 0}}><i className="fa fa-pencil" style={{pointerEvents: 'none', color:"black"}}></i></button>
            </div>
          } 
          />
        ))}
      </div>
    ));

    const SortableListxs = SortableContainer(({ items }) => (
      <div className='row pl-3' style={{ overflowX: 'auto', maxWidth: '100%' }}>
        {items.map((photo, index) => (
          <SortableItem key={`item-${index}`} axis={"xy"} index={index} value={
            <div className="mr-2" style={{ position: "relative", display: "inline-block", border:"1px solid lightgray", backgroundColor: "#F5F5F5"}}>
              <img className="col-12 p-0" style={{height: 120, objectFit:"contain", overflow: "visible",  display: "block" }} src={`https://file.satisgaranti.com/${photo.path}`}/>
              <img style={{position: "absolute", top: 0, left: 0}} src={items[0].path === photo.path  ? Images.showCase : ''}/>
              <button className="btn btn-default btn-circle" title='Sil' onClick={() => this.deleteAdvertPhotoOnClick(photo.id)} style={{ position: "absolute", top: 0, right: 0}}><i className="fa fa-close" style={{pointerEvents: 'none', color:"black"}}></i></button>
              <button className="btn btn-default btn-circle" onClick={() => this.handleEdit(photo.id, photo.path)} style={{ position: "absolute", bottom: 0, right: 0}}><i className="fa fa-pencil" style={{pointerEvents: 'none', color:"black"}}></i></button>
            </div>
          }
          />
        ))}
      </div>
    ));
    
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      this.state.uploadLoading ?
      <div>
        <div className="d-flex justify-content-center"><strong>Dosyalar yüklenene kadar lütfen sayfada bekleyiniz!</strong></div>
        <div className="progress">
          <div className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      :
      <>
        <div className="row d-flex justify-content-end mb-3 pr-3">
          <button className="btn bg-orange text-white mr-2 d-none" onClick={this.deleteAdvertOnClick} title="İlan Sil"><i className="fa fa-trash"></i> Sil</button>
        </div>
        <div className="row">
          <div className="col">
            <div className="ibox">
              <div className="ibox-title d-flex justify-content-between text-darkblue pr-2">
                <h5>İlan Düzenle</h5>
                <div className="mb-2">
                  {this.state.consultants.length !== 0 ? <button className="btn border border-green text-green mr-2" onClick={() => this.setState({isOpenAssignConsultantModal: true})} title="Danışman Ata"><i className="fa fa-user"></i><span className="d-none d-sm-inline"> Danışman Ata</span></button> : ''}
                  <button className="btn border border-darkblue text-darkblue" title="Geri" onClick={() => {this.props.navigate(-1)}}><i className="fa fa-reply"></i></button>
                  <button className="btn border border-orange text-orange ml-2" onClick={() => this.saveAdvertOnClick()} title="Kaydet"><i className="fa fa-save"></i><span className="d-none d-sm-inline"> Kaydet</span></button>
                </div>
              </div>
              <AssignConsultantModal isOpen={this.state.isOpenAssignConsultantModal} modalTitle="İlana Danışman Ata" modalDescription="İlana danışman atamak için danışman seçmi yapınız !" onRequestClose={() => this.setState({isOpenAssignConsultantModal: false})} saveOnClick={consultantInfo => this.saveAssignConsultantOnClick(consultantInfo)} />
              <div className="ibox-content">
                <div className="row mb-2">
                  <div className="col">
                    <ul className="d-flex list-unstyled" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                      {this.state.categoryPath.map((category, index) => (
                        category.id > 0 || index > 0 ? 
                        <li key={index} className="breadcrumb-item">
                          <a className="text-orange" href="#" >
                            {category.name}
                          </a>
                        </li>
                        : null
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="tabs-container">
                  <ul className="nav nav-tabs">
                    <li><Link className={this.state.selectedTabIndex === 0 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Müşteri Detay" to={'/advertEdit?id=' + this.props.searchParams.get('id') + '&tab=0'}>Genel İlan Bilgileri</Link></li>
                    {this.state.advertPhotos.length !== 0 && <li><Link className={this.state.selectedTabIndex === 1 ? 'nav-link pr-1 pl-1 pr-md-2 pl-md-2 active' : 'nav-link pr-1 pl-1 pr-md-2 pl-md-2'} title="Fotoğraflar" to={'/advertEdit?id=' + this.props.searchParams.get('id') + '&tab=1'}>Fotoğraflar ({this.state.advertPhotos.length})</Link></li>}
                  </ul>
                  <div className="tab-content">
                    <div className={this.state.selectedTabIndex === 0 ? 'tab-pane active' : 'tab-pane'}>
                      <div className="panel-body">
                        <div className="row mb-3">
                          <div className="col-12 d-flex justify-content-between">
                            <h4 className="text-orange">Genel İlan Bilgileri</h4>
                            <div className="d-flex">
                              <div className="mr-2">
                                <button className="btn border" type="button" onClick={() => this.setState({isOpenAdvertLangugeAddModal: true})} title="Çeviri Ekle"><i className="fa fa-plus"></i></button>
                              </div>
                              <div className="mr-2">
                                <button className="btn border" onClick={() => this.deleteAdvertLanguageOnClick(this.state.advertLanguages[this.state.selectedLanguageIndex].id)} title="Çeviri Sil"><i className="fa fa-trash"></i></button>
                              </div>
                              <div>
                                <select className="form-control form-control-sm form-input bg-gray" onChange={this.contentOnChange} value={this.state.advertLanguages[this.state.selectedLanguageIndex].id}>
                                  {this.state.advertLanguages.map((advertLanguageInfo) => <option key={advertLanguageInfo.id} value={advertLanguageInfo.id}>{advertLanguageInfo.language}</option>)}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hr-line-dashed mt-1"></div>
                        <AdvertLanguageAddModal advertId={parseInt(this.props.searchParams.get('id'))} isOpen={this.state.isOpenAdvertLangugeAddModal} modalTitle="Çeviri Ekle" modalDescription="İlana ait çeviri eklemek için aşağıdaki alanları doldurunuz !" onAdvertLanguageAdded={this.onAdvertLanguageAdded} onRequestClose={() => this.setState({isOpenAdvertLangugeAddModal: false})} />
                        <div className="row mb-2">
                          <div className="col">
                            <h5 className="text-darkblue"><strong>İlan Başlığı<strong className="text-danger ml-1">*</strong></strong></h5>
                            <input className="form-control form-input mb-2 mt-1" onChange={this.titleOnChange} onKeyDown={this.handleKeyPress} placeholder="İlan Başlığı" type="text" value={this.state.advertLanguages[this.state.selectedLanguageIndex].title} />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col">
                            <h5 className="text-darkblue"><strong>İlan Açıklaması</strong></h5>
                            <textarea className="form-control form-input mb-2 mt-1" onChange={this.descriptionOnChange} onKeyDown={this.handleKeyPress} placeholder="İlan Açıklaması" type="text" value={this.state.advertLanguages[this.state.selectedLanguageIndex].description} />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col">
                            <h5 className="text-darkblue"><strong>İlan Notu <strong className="ml-1">(Sadece siz görebilirsiniz.)</strong></strong></h5>
                            <textarea className="form-control form-input mb-2 mt-1"  onChange={this.commentOnChange} placeholder="İlan Notu" type="text" value={this.state.datas.comment} />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col">
                            <h5 className="text-darkblue"><strong>İlan İçeriği</strong></h5>
                            <Editor
                              editorClassName="editor-class pl-2"
                              editorState={this.state.content}
                              onEditorStateChange={(editorState) => this.setState({content: editorState})}
                              editorStyle={{border: '1px solid #F1F1F1', height: '25vh'}}
                              onKeyDown={this.handleKeyPress}
                            ></Editor>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col">
                            <h5 className="text-darkblue" ref={this.scrollRef}><strong>Fotoğraf</strong></h5>
                            <div className="ibox-content">
                              <SortableListxs axis={"xy"} items={this.state.advertPhotos} onSortEnd={this.onSortEnd}/>
                              <form className="d-flex align-items-center justify-content-center dropzone">
                                <div className="fallback">
                                  <input accept=".jpg, .jpeg, .png, .pdf" multiple name="file" onChange={(e) => {this.setState({ files: e.target.files }, () => {this.saveFileUpload();});}} style={{paddingLeft:"15%"}} type="file"/>
                                </div>
                              </form>
                              <div className="d-flex justify-content-end">{this.state.advertPhotos.length} dosya yüklendi</div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col">
                            <h5 className="text-darkblue" ref={this.scrollRefFile}><strong>Evraklar (Bu dosyalar site üzerinde yayınlanmayacaktır, size özeldir.)</strong></h5>
                            <div className="ibox-content">
                              <div className='row pl-3'>
                                {this.state.advertFiles.map((file) => (
                                  <div className="file-box" key={file.id}>
                                    <div className="file">
                                      <div className="d-flex justify-content-end">
                                        <button title="Sil" className="btn border mr-1 mt-1" onClick={() => this.deleteAdvertFileOnClick(file.id)}>
                                          <i className="fa fa-close"></i>
                                        </button>
                                      </div>
                                      <a title="Görüntüle" target="_blank" href={'https://file.satisgaranti.com/' + file.path}>
                                        <span className="corner"></span>
                                        <div className="icon">
                                          <i className="fa fa-file"></i>
                                        </div>
                                        <div className="file-name">
                                          {file.filename} <br /><small className="text-black">{isoToDateTime(file.addedDate)}</small>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <form className="d-flex align-items-center justify-content-center dropzone">
                                <div className="fallback">
                                  <input accept=".jpg, .jpeg, .png, .pdf" multiple name="file" onChange={(e) => {this.setState({ files: e.target.files }, () => {this.saveAdvertFileUpload();});}} style={{paddingLeft:"15%"}} type="file"/>
                                </div>
                              </form>
                              <div className="d-flex justify-content-end">{this.state.advertFiles.length} dosya yüklendi</div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-12">
                            <h4 className="text-orange">İlana Ait Özel Alanlar</h4>
                            <div className="hr-line-dashed mt-0"></div>
                          </div>
                          <div className="col-12">
                            {this.state.advertFields.map((advertFieldInfo) => <CustomWidgetPrepare key={advertFieldInfo.optionId} defaults={this.state.datas} defaultOnChange={(defaults) => this.setState({datas: defaults})} fieldInfo={advertFieldInfo} />)}
                            {this.state.advertFields.length === 0 && <div className="d-flex justify-content-center bg-muted"><h4 className="font-normal">İlana Ait Özel Alan Bulunamadı !</h4></div>}
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-12">
                            <h4 className="text-orange">İlana Ait Özellikler</h4>
                            <div className="hr-line-dashed mt-0"></div>
                          </div>
                          <div className="col-12">
                            {this.state.advertAttributes.map((advertAttributeInfo) => <CustomWidgetPrepare key={advertAttributeInfo.optionId} defaults={this.state.datas} defaultOnChange={(defaults) => this.setState({datas: defaults})} fieldInfo={advertAttributeInfo} />)}
                            {this.state.advertAttributes.length === 0 && <div className="d-flex justify-content-center bg-muted"><h4 className="font-normal">İlana Ait Özellik Bulunamadı !</h4></div>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <h4 className="text-orange">Konum Bilgisi</h4>
                            <div className="hr-line-dashed mt-0"></div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-4">
                                <h5 className="text-darkblue"><strong>Ülke<strong className="text-danger ml-1">*</strong></strong></h5>
                                <select className="form-control form-control-sm form-input mb-2 mt-1" disabled={true} onChange={this.countryOnChange} value={this.state.countryId}>
                                  <option value="">Seçiniz</option>
                                  {this.state.countries.map((countryInfo) => <option key={countryInfo.id} value={countryInfo.id}>{countryInfo.name}</option>)}
                                </select>
                              </div>
                              <div className="col-md-4">
                                <h5 className="text-darkblue"><strong>İl<strong className="text-danger ml-1">*</strong></strong></h5>
                                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.cityOnChange} value={this.state.cityId}>
                                  <option value="">Seçiniz</option>
                                  {this.state.cities.map((cityInfo) => <option key={cityInfo.id} value={cityInfo.id}>{cityInfo.name}</option>)}
                                </select>
                              </div>
                              <div className="col-md-4">
                                <h5 className="text-darkblue"><strong>İlçe<strong className="text-danger ml-1">*</strong></strong></h5>
                                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.townOnChange} value={this.state.townId}>
                                  <option value="">Seçiniz</option>
                                  {this.state.towns.map((townInfo) => <option key={townInfo.id} value={townInfo.id}>{townInfo.name}</option>)}
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <h5 className="text-darkblue"><strong>Semt<strong className="text-danger ml-1">*</strong></strong></h5>
                                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.districtOnChange} value={this.state.districtId}>
                                  <option value="">Seçiniz</option>
                                  {this.state.regions.map((regionInfo) => <option key={regionInfo.id} value={regionInfo.id}>{regionInfo.name}</option>)}
                                </select>
                              </div>
                              <div className="col-md-4">
                                <h5 className="text-darkblue"><strong>Mahalle<strong className="text-danger ml-1">*</strong></strong></h5>
                                <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.quarterOnChange} value={this.state.quarterId}>
                                  <option value="">Seçiniz</option>
                                  {this.state.quarters.map((quarterInfo) => <option key={quarterInfo.id} value={quarterInfo.id}>{quarterInfo.name}</option>)}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-3">
                            <div className="google-maps">
                              {this.state.lat !== null && this.state.lng !== null &&
                                <GoogleMapReact bootstrapURLKeys={{key: "AIzaSyDFDWC7Et5MZXsiJJ2qd_NTT6JdENiMIcM"}} center={{lat: this.state.lat, lng: this.state.lng}} zoom={this.state.zoom} defaultCenter={{lat: this.state.lat, lng: this.state.lng}} defaultZoom={this.state.zoom} onClick={(event) => this.setState({lat: event.lat, lng: event.lng})}>
                                  <Marker lat={this.state.lat} lng={this.state.lng}></Marker>
                                </GoogleMapReact>
                              }
                            </div>
                            <div className="d-flex justify-content-end mt-2"><button className="btn border border-orange text-orange" onClick={() => this.saveAdvertOnClick()} title="Kaydet"><i className="fa fa-save"></i> Kaydet</button></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={this.state.selectedTabIndex === 1 ? 'tab-pane active' : 'tab-pane'}>
                      <div className="panel-body">
                        <div className="row">
                          <div className="col">
                            <div className="ibox">
                              <div className="text-darkblue">
                                <div className="d-flex justify-content-between">
                                  <h4 className="text-orange">İlan Fotoğrafları</h4>
                                </div>                      
                                <div className="hr-line-dashed"></div>
                                {this.state.advertPhotos.length !== 0 ?
                                <SortableList items={this.state.advertPhotos} onSortEnd={this.onSortEnd} /> : <div className="d-flex justify-content-center">İlana Ait Fotoğraf Bulunamadı !</div>}
                                <AdvertRotationModal advertId={this.props.searchParams.get('id')} photoPath={this.state.photoPath} photoId={this.state.photoId} isOpen={this.state.isOpenRotationModal} modalTitle="Resim Döndürme" modalDescription=" " onRotationCompleted={this.onRotationCompleted} onRequestClose={() => this.setState({isOpenRotationModal: false})} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function AdvertEdit() {
  return (
    <AdvertEditModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}