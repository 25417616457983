import advertImage from '../assets/img/advertImage.jpg';
import logo from '../assets/img/logo.png';
import mobilePhoneIcon from '../assets/img/mobilePhoneIcon.png'
import userBannerImage1 from '../assets/img/userBannerImage1.jpg';
import userBannerImage2 from '../assets/img/userBannerImage2.jpg';
import userBannerImage3 from '../assets/img/userBannerImage3.jpg';
import userIcon from '../assets/img/userIcon.png';
import userIcon1 from '../assets/img/userIcon1.png';
import wlogo from '../assets/img/wlogo.png';
import messageBackground from '../assets/img/messageBackground.png';
import showCase from '../assets/img/showCase.png'
import consultantNotFound from '../assets/img/consultantNotFound.png';
import advertNotFound from '../assets/img/advertNotFound.png';
import messageNotFound from '../assets/img/messageNotFound.png';
import phoneNotFound from '../assets/img/phoneNotFound.png';
import favoriteAdvertNotFound from '../assets/img/favoriteAdvertNotFound.png';
import favoriteSellerNotFound from '../assets/img/favoriteSellerNotFound.png';
import favoriteSearchNotFound from '../assets/img/favoriteSearchNotFound.png';
import identityValidation from '../assets/img/identityValidation.png';
import mailValidation from '../assets/img/mailValidation.png';
import phoneValidation from '../assets/img/phoneValidation.png';


const Images = {
  advertImage,
  logo,
  mobilePhoneIcon,
  userBannerImage1,
  userBannerImage2,
  userBannerImage3,
  userIcon,
  userIcon1,
  wlogo,
  messageBackground,
  showCase,
  consultantNotFound,
  advertNotFound,
  messageNotFound,
  phoneNotFound,
  favoriteAdvertNotFound,
  favoriteSellerNotFound,
  favoriteSearchNotFound,
  identityValidation,
  mailValidation,
  phoneValidation
}

export default Images;