let clientData = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
  username: '',
  deviceId: '',
  sessionHash: '',
  sessionType: '',
  token: ''
};
  
export default clientData;