import React from 'react';
import swal from 'sweetalert';
import {Link, useNavigate} from 'react-router-dom';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import Images from '../../utils/Images';
import {isoToDate, slugify} from '../../inc/helper';

class MessagesModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      messages: [],
      advertPhotos: []
    };
  }

  componentDidMount() {
    this.getMessages();
    window.scrollTo(0, 0);
    console.log("Messages: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("Messages: componentWillUnmount()");
  }

  getMessages() {
    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
  };

  fetch(config.restApi + "messages/tr", requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({isLoading: false, messages: responseData.messages, advertPhotos: responseData.advertPhotos});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col">
            <h3 className="text-darkblue">Mesajlarım</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row d-none">
          <div className="col">
            <div className="input-group mt-1 mt-lg-0">
              <input className="input form-control form-input" placeholder="İlan no veya gönderen adına göre arama yapabilirsiniz." type="text" />
              <div className="input-group-append"><button className="btn btn bg-orange"><i className="fa fa-search text-white"></i></button></div>
            </div>
          </div>
        </div>
        <div className="row mt-3 d-none">
           <div className="col d-flex justify-content-end">
              <div className="mr-2 d-none">
                <select className="form-control form-control-sm form-input">
                  <option>Tüm Mesajlar</option>
                  <option>Get Mesajları</option>
                  <option>İlan Mesajları</option>
                </select>
              </div>
            <button className="btn bg-gray mr-2" title="Liste"><i className="fa fa-list-ul text-darkblue"></i></button>
            <button className="btn bg-gray" title="Galeri"><i className="fa fa-th text-darkblue"></i></button>  
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="tabs-container">
            <ul className="nav nav-tabs">
              <li><a className="nav-link pr-1 pl-1 pr-md-2 pl-md-2 active" data-toggle="tab" href="#messages" title="Normal">Tümü</a></li>
              <li><a className="nav-link pr-1 pl-1 pr-md-2 pl-md-2" data-toggle="tab" href="#unSeenMessages" title="Normal">Okunmamış</a></li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active w-100 col-12 px-0" id="messages">
                <div className="panel-body">
                  <div className="row">
                    <div className="col">
                      <div className="table-responsive-lg">
                        <table className="table table-bordered text-center">
                          {this.state.messages.length !== 0 &&
                            <thead>
                              <tr className="text-darkblue">
                                <th>İlan Görseli</th>
                                <th>İlan Başlığı</th>
                                <th>Mesajlaşma Tarihi</th>
                                <th>Okundu Bilgisi</th>
                                <th>Görüntüle</th>
                              </tr>
                            </thead>
                          }
                          <tbody>
                            {
                              this.state.messages.map((messageInfo) => 
                              <tr key={messageInfo.id}>
                                <td className="align-middle">
                                  {messageInfo.advertPhotos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${messageInfo.advertPhotos[0].path}`}/>
                                  : <i className="fa fa-camera d-flex align-items-center justify-content-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2'}}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{messageInfo.advertId}</strong>
                                </td>
                                <td className="align-middle"><Link target='_blank' to={`https://${config.host}/ilan/${slugify(messageInfo.advertTitle)}-${messageInfo.advertId}/detay`}>{messageInfo.advertTitle}</Link></td>
                                <td className="align-middle">{isoToDate(messageInfo.modifiedDate)}</td>
                                <td className="align-middle">{messageInfo.seen === 1 ? <h2 className="text-green" title="Okundu"><a className="fa fa-envelope-open"></a></h2> : <h2 className="text-danger" title="Okunmamış"><a className="fa fa-envelope"></a></h2>}</td>
                                <td className="align-middle"><Link className="btn border border-orange text-center mr-sm-2" to={'/messageDetails?id=' + messageInfo.id} title="Görüntüle"><i className="fa fa-eye text-orange"></i></Link></td>
                              </tr>
                            )}
                            {this.state.messages.length === 0 &&
                              <div className="col-12 d-block notFoundImg">
                                <div className="text-center">
                                  <img className="m-4" src={Images.messageNotFound}/>
                                </div>
                                <div className="text-center my-auto">
                                  <h2 className="mb-1 mt-2"><b className="text-orange">Mesajınız Bulunamadı!</b></h2>
                                  <h2 className="m-0 pb-4 text-darkblue">Son 1 yıl içerisindeki mesajlarınız listelenmektedir.</h2>
                                </div>
                              </div>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane w-100 col-12 px-0" id="unSeenMessages">
                <div className="panel-body">
                  <div className="row">
                    <div className="col">
                      <div className="table-responsive-lg">
                        <table className="table table-bordered text-center">
                          {this.state.messages.filter((filterMessage) => filterMessage.seen === 0).length !== 0 && 
                            <thead>
                              <tr className="text-darkblue">
                                <th>İlan Görseli</th>
                                <th>İlan Başlığı</th>
                                <th>Mesajlaşma Tarihi</th>
                                <th>Okundu Bilgisi</th>
                                <th>Görüntüle</th>
                              </tr>
                            </thead>
                          }
                          <tbody>
                            {
                              this.state.messages.filter((filterMessage) => filterMessage.seen === 0).map((messageInfo) =>
                              (
                                <tr key={messageInfo.id}>
                                <td className="align-middle">
                                  {messageInfo.advertPhotos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${messageInfo.advertPhotos[0].path}`}/>
                                  : <i className="fa fa-camera d-flex align-items-center justify-content-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2'}}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{messageInfo.advertId}</strong>
                                </td>
                                <td className="align-middle">{messageInfo.advertTitle}</td>
                                <td className="align-middle">{isoToDate(messageInfo.modifiedDate)}</td>
                                <td className="align-middle">{<h2 className="text-danger" title="Okunmamış"><a className="fa fa-envelope"></a></h2>}</td>
                                <td className="align-middle"><Link className="btn border border-orange text-center mr-sm-2" to={'/messageDetails?id=' + messageInfo.id} title="Görüntüle"><i className="fa fa-eye text-orange"></i></Link></td>
                              </tr>
                              ))}
                              {this.state.messages.filter((filterMessage) => filterMessage.seen === 0).length === 0 && 
                              <div className="col-12 d-block notFoundImg">
                                <div className="text-center">
                                  <img className="m-4" src={Images.messageNotFound}/>
                                </div>
                                <div className="text-center my-auto">
                                  <h2 className="mb-1 mt-2"><b className="text-orange">Mesajınız Bulunamadı!</b></h2>
                                  <h2 className="m-0 pb-4 text-darkblue">Son 1 yıl içerisindeki mesajlarınız listelenmektedir.</h2>
                                </div>
                              </div>
                              }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function Messages(props) {
  return (
    <MessagesModule navigate={useNavigate()} />
  )
}