import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import swal from 'sweetalert';
import Images from '../utils/Images'

import clientData from '../inc/clientData';
import config from '../inc/config';
import {getCookie, removeCookie} from '../inc/cookies';


const items = [
  {id: 1, src: Images.userBannerImage2, alt: 'Image 1'},
  {id: 2, src: Images.userBannerImage1, alt: 'Image 2'},
  {id: 3, src: Images.userBannerImage3, alt: 'Image 3'},
]

class UserFrameModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      navigationList: [
        {id: 1, icon: 'fa-id-card', title: 'Hesap Özeti', items: [
          {id: 10, icon: 'fa-angle-double-right', title: 'Hesap Özeti', url: '/'},
        ]},
        {id: 2, icon: 'fa-user-circle', title: 'Hesabım', items: [
          {id: 20, icon: 'fa-angle-double-right', title: 'Kişisel Bilgilerim', url: '/accountInfo'},
          {id: 21, icon: 'fa-angle-double-right', title: 'Danışmanlarım', url: '/consultants'},
          {id: 22, icon: 'fa-angle-double-right', title: 'Paket Detaylarım', url: '/remainingProductDetails'},
          {id: 23, icon: 'fa-angle-double-right', title: 'Şifre Değiştir', url: '/changePassword'},
          {id: 24, icon: 'fa-angle-double-right', title: 'Hesap İptali', url: '/accountCancellation'}
        ]},
        {id: 3, icon: 'fa-heart', title: 'Favorilerim', items: [
          {id: 30, icon: 'fa-angle-double-right', title: 'Favori İlanlarım', url: '/favoriteAdverts'},
          {id: 31, icon: 'fa-angle-double-right', title: 'Favori Aramalarım', url: '/favoriteSearches'},
          {id: 32, icon: 'fa-angle-double-right', title: 'Favori Satıcılarım', url: '/favoriteSellers'}
        ]},
        {id: 4, icon: 'fa-list', title: 'İlanlarım', items: [
          {id: 40, icon: 'fa-angle-double-right', title: 'Tüm İlanlarım', url: '/adverts'},
          {id: 41, icon: 'fa-angle-double-right', title: 'Yayında Olan İlanlarım', url: '/activeAdverts'},
          {id: 42, icon: 'fa-angle-double-right', title: 'Yayında Olmayan İlanlarım', url: '/passiveAdverts'},
          {id: 43, icon: 'fa-angle-double-right', title: 'Onay Bekleyen İlanlarım', url: '/pendingAdverts'},
          {id: 44, icon: 'fa-angle-double-right', title: 'Ücretsiz İlan Ver', url: '/createAdvert'}
        ]},
        {id: 5, icon: 'fa-envelope', title: 'Mesaj ve Bilgilendirmeler', items: [
          {id: 50, icon: 'fa-angle-double-right', title: 'Mesajlarım', url: '/messages'}
        ]},
        {id: 6, icon: 'fa-id-card', title: 'Doğrulama Merkezi', items: [
          {id: 60, icon: 'fa-angle-double-right', title: 'e-Devlet', url: '/identityValidation'},
          {id: 61, icon: 'fa-angle-double-right', title: 'Telefon Numarası', url: '/phoneValidation'},
          {id: 62, icon: 'fa-angle-double-right', title: 'E-posta', url: '/mailValidation'},
        ]},
      ],

      memberInfo: {
        firstname: '',
        lastname: '',
        username: '',
        image: null
      },

      email: 0,
      identity: 0,
      phone:0,

      activeIndex: 0
    }
  }

  componentDidMount() {
    this.timerID = setInterval(() => {this.slideCarousel();}, 5000);
    this.getSessionInfo();
    console.log("UserFrame: ComponentDidMount()");
  }

  componentWillUnmount() {
    //bellek sızıntısını önlemek için id'i sil ve bu işlemi tamamen durdur boşa bellekte yer etmesin
    clearInterval(this.timerID);
    console.log("UserFrame: componentWillUnmount()");
  }

  getSessionInfo() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining == 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'memberInfo', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          clientData.id = responseData.memberInfo.id
          clientData.email = responseData.memberInfo.email
          this.setState({memberInfo: responseData.memberInfo});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'validationStatus', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          this.setState({email: responseData.email, identity: responseData.identity, phone: responseData.phone});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getInsuranceLink = () => {
    
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    this.setState({isLoading: true})

    fetch(config.restApi + 'insuranceLink/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      this.setState({isLoading: false})

      switch (responseData.status) {
        case 200:
          window.open(responseData.insuranceLink, '_blank');
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet basadadğlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  userLogoutOnClick = (event) => {
    event.preventDefault();

    swal({
      dangerMode: true,
      icon: 'info',
      title: 'Onayla',
      text: 'Oturumu kapatmak istediğinize emin misiniz ?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then((value) => {
      if (value) {
        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
        };

        fetch(config.restApi + 'logout', requestOptions)
        .then(response => response.json())
        .then(responseData => responseData.status === 200 && removeCookie('sessionHash') && window.location.replace('/'), () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  slideCarousel = () => {
    const lastIndex = items.length - 1;
    const shouldResetIndex = this.state.activeIndex === lastIndex;
    const index = shouldResetIndex ? 0 : this.state.activeIndex + 1;
    this.setState({activeIndex: index})
  }

  render() {
    return (
      this.state.isLoading ? 
      <div className="ibox-content sk-loading gray-bg w-100 h-100">
      <div className="sk-spinner sk-spinner-fading-circle">
        <div className="sk-circle1 sk-circle"></div>
        <div className="sk-circle2 sk-circle"></div>
        <div className="sk-circle3 sk-circle"></div>
        <div className="sk-circle4 sk-circle"></div>
        <div className="sk-circle5 sk-circle"></div>
        <div className="sk-circle6 sk-circle"></div>
        <div className="sk-circle7 sk-circle"></div>
        <div className="sk-circle8 sk-circle"></div>
        <div className="sk-circle9 sk-circle"></div>
        <div className="sk-circle10 sk-circle"></div>
        <div className="sk-circle11 sk-circle"></div>
        <div className="sk-circle12 sk-circle"></div>
      </div>
    </div>
    :
      <>
      <div className="navbar navbar-expand bg-darkblue d-block d-sm-flex justify-content-between">
        <div className="text-center"><Link to='/'><img alt="Satış Garanti Logo" src={Images.wlogo} /></Link></div>
          <ul className="nav navbar-nav d-flex justify-content-center"> 
            <li className="nav-item">
              <div className="input-group">
                <h3 className="btn dropdown-toggle text-underline-black font-bold text-white px-1" data-toggle="dropdown">{this.state.memberInfo.firstname} {this.state.memberInfo.lastname}</h3>
                <ul className="dropdown-menu">
                  <li><Link className="dropdown-item font-bold" to={'/'}><i className="fa fa-id-card text-darkblue"></i> Hesap Özeti</Link></li>
                  <li><Link className="dropdown-item font-bold" to={'/adverts'}><i className="fa fa-list text-darkblue"></i> İlanlarım</Link></li>
                  <li className="dropdown-divider"></li>
                  <li><Link className="dropdown-item font-bold" to={'/favoriteAdverts'}><i className="fa fa-heart text-darkblue"></i> Favorilerim</Link></li>
                  <li className="dropdown-divider"></li>
                  <li><a className="dropdown-item font-bold d-none" href="#">Sepetim</a></li>
                  <li className="dropdown-divider d-none"></li>
                  <li><a className="dropdown-item font-bold d-none" href="#">Alış İşlemlerim</a></li>
                  <li><a className="dropdown-item font-bold d-none" href="#">Satış İşlemlerim</a></li>
                  <li className="dropdown-divider d-none"></li>
                  <li><Link className="dropdown-item font-bold" to={'/accountInfo'}><i className="fa fa-user-circle text-darkblue"></i> Hesabım</Link></li>
                  <li><Link className="btn font-bold" onClick={this.userLogoutOnClick} title="Oturumu Kapat"><i className="fa fa-sign-out text-darkblue"></i> Çıkış Yap</Link></li>
                </ul>
              </div>
            </li>
            <li className="nav-item d-none">
              <div className="input-group">
                <span className="btn font-bold pointer" data-toggle="dropdown" title="Mesajlarım"><h3><i className="fa fa-envelope"></i></h3></span>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item pl-1" href="#">Mesajlarım</a></li>
                  <li className="dropdown-divider"></li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <div className="input-group">
                <span className="btn font-bold pointer text-orange px-1" title="Mesajlarım"><h3><Link className="text-dark" to={'/messages'}><i className="fa fa-envelope text-white"></i></Link></h3></span>
              </div>
            </li>
            <li className="nav-item">
              <div className="input-group">
                <span className="btn font-bold pointer text-darkblue px-1" title="Favori İlanlarım"><h3><Link className="text-dark" to={'/favoriteAdverts'}><i className="fa fa-heart text-white"></i></Link></h3></span>
              </div>
            </li>
            <li className="nav-item ml-md-2">
              <div className="input-group mt-2">
                <Link className="btn btn-outline font-bold text-white bg-orange w-100 d-block d-md-none pointer" title="Ücretsiz İlan Ver" to={'/createAdvert'}><i className="fa fa-plus"/></Link>
                <Link className="btn btn-outline font-bold text-white bg-orange w-100 d-none d-md-flex" to={'/createAdvert'}>Ücretsiz İlan Ver</Link>
              </div>
            </li>
          </ul>
      </div>
      <div className="row" style={{marginRight:"auto"}}>
        <div className="col-12 carousel p-0">
          <div className="carousel-items">
            {this.state.email === 0 &&
            <div class="alert alert-danger mb-0">
              E-posta adresiniz doğrulanmamıştır. İlan yayınlayabilmek için E-posta adresinizi <a class="alert-link" href="/mailValidation">bu linke tıklayarak doğrulamanız gerekmektedir.</a>
            </div>
            }
            {this.state.phone === 0 &&
            <div class="alert alert-danger m-0">
              Telefon numaranız doğrulanmamıştır. İlan yayınlayabilmek için telefon numarasını <a class="alert-link" href="/phoneValidation">bu linke tıklayarak doğrulamanız gerekmektedir.</a>
            </div>
            }
            {items.map((itemInfo, index) => (
              <div key={itemInfo.id} className={index === this.state.activeIndex ? "carousel-item active" : "carousel-item"}>
                <img alt={itemInfo.alt} className="w-100" src={itemInfo.src}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row mt-2" style={{marginLeft: '-2px', marginRight:"auto"}}>
        <div className="col-lg-3 col-xl-2 pr-0 d-none d-lg-flex">
          <div className="ibox">
            <div className="ibox-content p-0">
              <ul className="nav nav-metismenu d-block">
                <li className="nav-header text-center pb-0">
                  <div className="profile-element">
                    <img alt="userImage" className="rounded-circle w-50 h-50" src={Images.userIcon} />
                    <span className="block font-bold text-darkblue mt-3">{this.state.memberInfo.firstname} {this.state.memberInfo.lastname}</span>
                  </div>
                </li>
                <div className="hr-line-dashed"></div>
                {this.state.navigationList.map((navigationInfo, index) =>
                  <div className="py-2 px-2" key={index}>
                    <li className="text-left mb-2" key={navigationInfo.id}>
                      <Link to={navigationInfo.url || '#'}><i className={"text-darkblue  fa " + navigationInfo.icon}></i> <span className="text-darkblue font-bold">{navigationInfo.title}</span></Link>
                      {navigationInfo.items.length > 0 ?
                        <ul className="nav nav-second-level d-block">
                          {navigationInfo.items.map(itemInfo => <li key={itemInfo.id}><Link to={itemInfo.url}><i className={"fa " + itemInfo.icon}></i> <span className="text-darkblue">{itemInfo.title}</span></Link></li>)}
                        </ul>
                      : null}
                    </li>
                  </div>
                )}
                <div className="pt-2 px-2">
                  <li className="text-left">
                    <Link to={'#'}><i className={"text-darkblue fa fa-cubes"}></i> <span className="text-darkblue font-bold">Servislerim</span></Link>
                    <ul className="nav nav-second-level d-block">
                      <li><a href={`https://www.${config.host}`} target="_blank"><i className={"fa fa-angle-double-right"}></i> <span className="text-darkblue">Satış Garanti Anasayfa</span></a></li>
                      <li><a href={`https://kredi.${config.host}`} target="_blank"><i className={"fa fa-angle-double-right"}></i> <span className="text-darkblue">Kredi İşlemleri</span></a></li>
                      <li><a href={`https://ekspertiz.${config.host}`} target="_blank"><i className={"fa fa-angle-double-right"}></i> <span className="text-darkblue">Ekspertiz İşlemleri</span></a></li>
                      <li><a href={`https://cekici.${config.host}`} target="_blank"><i className={"fa fa-angle-double-right"}></i> <span className="text-darkblue">Çekici İşlemleri</span></a></li>
                      <li><a href="javascript:void(0)" onClick={this.getInsuranceLink}><i className={"fa fa-angle-double-right"}></i> <span className="text-darkblue">Sigorta İşlemleri</span></a></li>
                    </ul>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-12 d-lg-none d-xl-none pr-0">
          {this.state.navigationList.map((navigationInfo, index) => (
            <div className="border" key={index}>
              <button className="btn btn-block bg-light font-bold text-darkblue" data-toggle="collapse" data-target={`#category${navigationInfo.id}`}> <i className={`fa ${navigationInfo.icon} mr-1`}></i>{navigationInfo.title}</button>
              <div className="collapse" id={`category${navigationInfo.id}`}>
                <div className="card card-body p-0 text-center">
                  <ul className="unstyled mt-2 pl-0">
                    {navigationInfo.items.map((itemInfo, index) => (
                      <li key={index} className="mb-2">
                        <Link to={itemInfo.url} className="text-darkblue underline-darkblue font-bold">
                          {itemInfo.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
            <div className="border">
              <button className="btn btn-block bg-light font-bold text-darkblue" data-toggle="collapse" data-target={`#categorys`}><i className={`fa fa-cubes mr-1`}></i>Servislerim</button>
              <div className="collapse" id={`categorys`}>
                <div className="card card-body p-0 text-center">
                  <ul className="unstyled mt-2 pl-0">
                    <li className="mb-2"><a className="text-darkblue underline-darkblue font-bold" href={`https://www.${config.host}`} target="_blank"><span className="text-darkblue">Satış Garanti Anasayfa</span></a></li>
                    <li className="mb-2"><a className="text-darkblue underline-darkblue font-bold" href={`https://kredi.${config.host}`} target="_blank"><span className="text-darkblue">Kredi İşlemleri</span></a></li>
                    <li className="mb-2"><a className="text-darkblue underline-darkblue font-bold" href={`https://ekspertiz.${config.host}`} target="_blank"><span className="text-darkblue">Ekspertiz İşlemleri</span></a></li>
                    <li className="mb-2"><a className="text-darkblue underline-darkblue font-bold" href={`https://cekici.${config.host}`} target="_blank"><span className="text-darkblue">Çekici İşlemleri</span></a></li>
                    <li className="mb-2"><a className="text-darkblue underline-darkblue font-bold" href="javascript:void(0)" onClick={this.getInsuranceLink}><span className="text-darkblue">Sigorta İşlemleri</span></a></li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
        <div className="col-md-12 col-lg-9 col-xl-10 pr-0">
          <div className="ibox">
            <div className="ibox-content">
            {this.props.children}
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default function UserFrame(props) {
  return (
    <UserFrameModule navigate={useNavigate()}>{props.children}</UserFrameModule>
  )
}