import React from 'react';
import swal from 'sweetalert';
import GoogleMapReact from 'google-map-react';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import clientData from '../../inc/clientData';
import {  Link, useNavigate, useSearchParams } from 'react-router-dom';

const Marker = () => (<i className="fa fa-map-marker fa-5x text-danger ml-n3 mt-n5"></i>);
class SelectConsultantPointModule extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoading: false,
  
        memberId: clientData.id,
        continueButton: false,
  
        cities: [],
        towns: [],
        regions: [],
        quarters: [],
        consultantCompanies: [],

        lat: 39.886559,
        lon: 32.855350,
        zoom: 5,
        
        limit: null,
        cityId: 0,
        townId: 0,
        districtId: 0,
        quarterId: 0
      };
      this.handleCitySelect = this.handleCitySelect.bind(this);
      this.handleTownSelect = this.handleTownSelect.bind(this);
      this.handleRegionSelect = this.handleRegionSelect.bind(this);
      this.handleQuarterSelect = this.handleQuarterSelect.bind(this);
    }
    
  componentDidMount() {
    this.getCities()
    window.scrollTo(0, 0);
    console.log("SelectConsultantModal: componentDidMount()");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categoryId !== this.props.categoryId) {
      this.setState({categoryId: this.props.categoryId});
    }
  }

  componentWillUnmount() {
    console.log("SelectConsultantModal: componentWillUnmount()");
  }

  getCities() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + sessionHash },
    };

    let errorMessages = [];
    let remaining = 3;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({ isLoading: false });
      }
    };

    fetch(config.restApi + 'cities/tr/'+ 1, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              this.setState({isLoading: false, cities: responseData.cities});
              break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
          complete();
        }, () => swal({ dangerMode: true, icon: "error", title: "Bağlantı Hatası", text: "Lütfen internet bağlantınızı kontrol ediniz.", buttons: "Tamam"})
      );

      fetch(config.restApi + 'consultantCompanies/tr', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({isLoading: false, consultantCompanies: [{id: clientData.id, name: 'Kendi Hesabım için yayınla'}, ...responseData.consultantCompanies]});
          }
          break;
          default: {
            this.setState({isLoading: false});
  
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => this.props.navigate('/home'));
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

      fetch(config.restApi + 'memberProductStatus/tr/' + 2, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              {
                this.setState({ limit: responseData.limit });
              }
              break;
            default:
              {
                errorMessages.push(responseData.message);
              }
              break;
          }
          complete();
        },
        () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.')
      );
  }

  getTowns(cityId) {

    const requestOptions = {
      method: 'GET',
    };

    fetch(config.restApi + 'towns/tr/'+ cityId, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              this.setState({isLoading: false, towns: responseData.towns});
              break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
        },
        () =>
          swal({
            dangerMode: true,
            icon: "error",
            title: "Bağlantı Hatası",
            text: "Lütfen internet bağlantınızı kontrol ediniz.",
            buttons: "Tamam",
          })
      );
  }

  getRegions(townId) {

    const requestOptions = {
      method: 'GET',
    };

    fetch(config.restApi + 'regions/tr/'+ townId, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              this.setState({isLoading: false, regions: responseData.regions});
              break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
        },
        () =>
          swal({
            dangerMode: true,
            icon: "error",
            title: "Bağlantı Hatası",
            text: "Lütfen internet bağlantınızı kontrol ediniz.",
            buttons: "Tamam",
          })
      );
  }

  saveOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = "";

    if (!this.state.cityId) {
      infoMessage = "İl boş geçilemez !";
    } else if (!this.state.townId) {
      infoMessage = "İlçe boş geçilemez !";
    } else if (!this.state.districtId) {
      infoMessage = "Semt boş geçilemez !";
    } else if (!this.state.quarterId) {
      infoMessage = "Mahalle boş geçilemez !";
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: "info",
        title: "Eksik Bilgi",
        text: infoMessage,
        buttons: "Tamam",
      });
    } else {
      this.setState({ isLoading: true });

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({categoryId: parseInt(this.props.searchParams.get('id')), memberId: parseInt(this.props.searchParams.get('memberId')) || this.state.memberId, cityId: this.state.cityId, townId: this.state.townId, districtId: this.state.districtId, quarterId: this.state.quarterId, lat: this.state.lat, lon: this.state.lon})
      };
      fetch(config.restApi + 'advert/tr', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isLoading: true});
            window.location.href= `/advertEdit?id=${responseData.advertId}`;
          }
          break;
          case 205: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'İlanlarımı Görüntüle'
            }).then(() => this.props.navigate('/adverts'));
          }
          break;
          case 406: {
            if (responseData.ref === "emailValidationError") {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'E-posta Doğrulama',
                text: "İlan yayınlayabilmek için E-posta adresinizi doğrulamanız gerekli.",
                buttons: {confirm: 'Doğrula', cancel: 'Vazgeç'}
              }).then((value) => {
                if (value) {
                  this.props.navigate('/mailValidation');
                } else {
                  this.setState({isLoading: false});
                }
              }); 
            } else if(responseData.ref === "phoneValidationError") {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Telefon Numarası Doğrulama',
                text: "İlan yayınlayabilmek için telefon numarasını doğrulamanız gerekli.",
                buttons: {confirm: 'Doğrula', cancel: 'Vazgeç'}
              }).then((value) => {
                if (value) {
                  this.props.navigate('/phoneValidation');
                } else {
                  this.setState({isLoading: false});
                }
              });
            } else if(responseData.ref === "identityValidationError") {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'e-Devlet ile Hesap Doğrulama',
                text: "İkinci el araç ve emlak alım-satım ve kiralamasında güvenirliliği artırmak amacıyla Ticaret Bakanlığı'nın yayınladığı yönetmelik gereği, ilanınızı yayınlayabilmek için bir defaya mahsus olarak e-Devlet ile hesabınızı doğrulamanız gerekiyor.",
                buttons: {confirm: 'Doğrula', cancel: 'Vazgeç'}
              }).then((value) => {
                if (value) {
                  this.props.navigate('/identityValidation');
                } else {
                  this.setState({isLoading: false});
                }
              });
            }
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
            this.setState({isLoading: false});
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  handleCitySelect(event) {
    let cityId = event.target.value;
    const selectedCityId = this.state.cities.find((city) => city.id === parseInt(cityId));
    if (cityId) {
      this.getTowns(cityId);
      this.setState({cityId: parseInt(cityId), lat: selectedCityId.lat, lon: selectedCityId.lon, zoom: selectedCityId.zoom, townId: 0, districtId: 0, quarterId: 0});
    } else {
      this.setState({cityId: '', townId: 0, districtId: 0, quarterId: 0});
    }
  }

  handleTownSelect(event) {
    let townId = event.target.value;
    const selectedTownId = this.state.towns.find((town) => town.id === parseInt(townId));
    if (townId) {
      this.getRegions(townId);
      this.setState({ townId: parseInt(townId), lat: selectedTownId.lat !== 0 ? selectedTownId.lat : this.state.cityId.lat, lon: selectedTownId.lon !== 0 ? selectedTownId.lon : this.state.cityId.lon, zoom: selectedTownId.zoom});
    } else {
      this.setState({ townId: 0});
    }
  }

  handleRegionSelect(event) {
    let districtId = event.target.value;
    const selectedDistrict = this.state.regions.find((region) => region.id === parseInt(districtId));
    if (districtId) {
      this.setState({ districtId: parseInt(districtId), lat: selectedDistrict.lat !== 0 ? selectedDistrict.lat :this.state.townId.lat, lon: selectedDistrict.lon !== 0 ? selectedDistrict.lon :this.state.townId.lon, zoom: selectedDistrict.zoom, quarters: this.state.regions.filter(districtInfo => districtInfo.id === parseInt(districtId))[0].quarters});
    } else {
      this.setState({ districtId: 0});
    }
  }

  handleQuarterSelect(event) {
    let quarterId = event.target.value;
    const selectedQuarterId = this.state.quarters.find((quarter) => quarter.id === parseInt(quarterId));
    const selectedTownId = this.state.towns.find((town) => town.id === parseInt(this.state.townId));
    const selectedCityId = this.state.cities.find((city) => city.id === parseInt(this.state.cityId));
    if (selectedQuarterId) {
      this.setState({ quarterId: parseInt(quarterId), lat: selectedQuarterId.lat || selectedTownId.lat || selectedCityId.lat, lon: selectedQuarterId.lon || selectedTownId.lon || selectedCityId.lon, zoom: selectedQuarterId.zoom});
    } else {
      this.setState({ selectedQuarterId: 0});
    }
  }

  handleLinkClick = () => {
    this.setState({ continueButton: !this.state.continueButton });
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col">
            <h3 className="text-darkblue">İlan Konum Bilgisi</h3>   
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row">
          <div className="col">
            <div className="ml-sm-0">
              <div className="row mb-2">
                <div className="col-12 row pr-2">
                  <div className="col-12 pr-0">
                    <strong>il<strong className="text-danger ml-1">*</strong></strong>
                    <select className="form-control form-control-sm form-input mb-2 mt-1 b-r-sm" onChange={this.handleCitySelect} value={this.state.cityId}>
                      <option value="">Seçiniz</option>
                      {this.state.cities.map(cityInfo => <option key={cityInfo.id} value={cityInfo.id}>{cityInfo.name}</option>)}
                    </select>
                    <strong>İlçe<strong className="text-danger ml-1">*</strong></strong>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.handleTownSelect} value={this.state.townId}>
                      <option value="">Seçiniz</option>
                      {this.state.towns.map(townInfo => <option key={townInfo.id} value={townInfo.id}>{townInfo.name}</option>)}
                    </select>
                    <strong>Semt<strong className="text-danger ml-1">*</strong></strong>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.handleRegionSelect} value={this.state.districtId}>
                      <option value="">Seçiniz</option>
                      {this.state.regions.map(regionInfo => <option key={regionInfo.id} value={regionInfo.id}>{regionInfo.name}</option>)}
                    </select>
                    <strong>Mahalle<strong className="text-danger ml-1">*</strong></strong>
                    <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.handleQuarterSelect} value={this.state.quarterId}>
                      <option value="">Seçiniz</option>
                      {this.state.quarters.map((quarterInfo) => <option key={quarterInfo.id} value={quarterInfo.id}>{quarterInfo.name}</option>)}
                    </select>
                    <div className="d-flex justify-content-end mb-4"> 
                      <button className="btn btn-orange" onClick={this.saveOnClick}>Tamam</button>
                    </div>
                  </div>
                  <div className="col-12 pr-0">
                    <div className="google-maps">
                      {this.state.lat !== null && this.state.lon !== null &&
                        <GoogleMapReact bootstrapURLKeys={{key: "AIzaSyDFDWC7Et5MZXsiJJ2qd_NTT6JdENiMIcM"}} center={{lat: this.state.lat, lng: this.state.lon}} zoom={this.state.zoom} defaultCenter={{lat: this.state.lat, lng: this.state.lon}} defaultZoom={this.state.zoom} onClick={(event) => this.setState({lat: event.lat, lon: event.lng})}>
                          <Marker lat={this.state.lat} lng={this.state.lon}></Marker>
                        </GoogleMapReact>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function SelectConsultantPoint() {
  return (
    <SelectConsultantPointModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}