import React from 'react';
import Images from '../../utils/Images';
import clientData from '../../inc/clientData';
import config from '../../inc/config';
import swal from 'sweetalert';
import { getCookie } from '../../inc/cookies';
import PhoneModal from '../../components/PhoneModal';
import PhoneValidateModal from '../../components/PhoneValidateModal';
import { Link } from 'react-router-dom';

class PhoneValidationModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenPhoneModal: false,
      isOpenPhoneValidateModal: false,

      firstname: '',
      lastname: '',
      username: '',
      
      defaultNumber: '',
      identityVerified: '',
      verifiedPhoneNumber: '',

      memberPhones: [],
      memberPhoneId: '',

      editPhone: null,

      email: 0,
      identity: 0,
      phone: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getPersonalInformation();
    /* this.getValidationStatus(); */
    console.log("Consultants: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("Consultants: componentWillUnmount()");
  }

  getPersonalInformation() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining == 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: errorMessages[0], buttons: 'Tamam'}).then(this.props.navigate('/'));
        }else{
          this.setState({isLoading: false});
        }
      }
    }

    fetch(config.restApi + 'personalInformation/tr' , requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const defaultPhoneNumber = responseData.personalInformation.memberPhones.find((memberPhoneInfo) => memberPhoneInfo.defaultSelected !== 0);
          const defaultNumber = defaultPhoneNumber ? defaultPhoneNumber.phoneNumber : '-';
          this.setState({
            firstname: responseData.personalInformation.firstname,
            lastname: responseData.personalInformation.lastname,
            username: responseData.personalInformation.username,
            identityVerified: responseData.personalInformation.identityVerified,
            memberPhones: responseData.personalInformation.memberPhones,
            defaultNumber: defaultNumber,
          });
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'validationStatus', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          this.setState({email: responseData.email, identity: responseData.identity, phone: responseData.phone});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  saveMemberPhoneOnClick(phoneInfo) {
    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(phoneInfo)
    };

    fetch(config.restApi + 'memberPhone/tr' , requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Telefon numarası başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getPersonalInformation(), this.setState({isOpenPhoneModal: false}));
        break;
        case 406:
          this.accountAuthorizationOnClick(phoneInfo);
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.setState({isOpenPhoneModal: false}), swal.close());
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  accountAuthorizationOnClick() {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Hesap Güvenliği',
      text: 'Bilgilerinizi güncelleyebilmek için şifrenizi girmeniz gerekmektedir.',
      content: {
        element: 'input',
        attributes: {
          type: 'password',
          placeholder: 'Şifreniz'
        }
      },
      buttons: {
        confirm: 'Gönder',
        cancel: 'Vazgeç'
      }
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');
  
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json','Authorization': 'Bearer ' + sessionHash},
          body: JSON.stringify({password: value})
        };
  
        fetch(config.restApi + 'login', requestOptions)
          .then(response => response.json())
          .then(responseData => {
            switch (responseData.status) {
              case 200:
              break;
              case 404:
                swal({
                  dangerMode: true,
                  icon: 'warning',
                  title: 'Yanlış Şifre',
                  text: 'Girdiğiniz şifre yanlış. Lütfen tekrar deneyin.',
                  buttons: 'Tamam'
                }).then(() => this.accountAuthorizationOnClick());
                break;
              default:
                swal({
                  dangerMode: true,
                  icon: 'warning',
                  title: 'Hata',
                  text: responseData.message,
                  buttons: 'Tamam'
                });
                break;
            }
          }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  savePhoneValidateCodeOnClick(validationCode) {
    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({validationCode: validationCode})
    };

    fetch(config.restApi + 'phoneValidationCode/tr/' + this.state.memberPhoneId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Telefon numaranız doğrulanmıştır.',
            buttons: 'Tamam'
          }).then(() => this.setState({isOpenPhoneValidateModal: false}), this.getPersonalInformation())
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getPhoneTypes(id) {
    if(id === 1) {
      return 'Cep Telefonu'
    } else if(id === 2) {
      return 'Sabit Telefon'
    } else if(id === 3) {
      return 'Fax'
    } 
    return '-'
  }

  getPhoneNumber(memberPhoneInfo) {
    let phoneNumber = memberPhoneInfo.phoneNumber.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
    let phoneMasking = `(${phoneNumber[1]}) ${phoneNumber[2]} ${phoneNumber[3]} ${phoneNumber[4]}`;
    return phoneMasking;
  }

  phoneValidateCodeOnClick(memberPhoneInfo) {
    this.setState({isOpenPhoneValidateModal: true, memberPhoneId: memberPhoneInfo.id, verifiedPhoneNumber: memberPhoneInfo.phoneNumber});
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <h3 className="text-darkblue">Telefon Numarası</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row">
          <div className="col my-2">
            {this.state.phone === 1 &&
              <div className="table-responsive-lg">
                <h4 className="col-md-6 border border-green text-green p-2"><i className="fa fa-check mr-1"></i>Telefon numarası başarıyla doğrulanmıştır.</h4>
              </div>
            }
            <div className="table-responsive-lg">
              <h4 className="text-darkblue col-md-6 p-0">Telefon numarası yalnızca hesabınızın doğrulanması ve hesap işlemleri ile ilgili bildirimlerin iletilmesi amacıyla kullanılacak olup, sizin izniniz olmadan başkalarıyla paylaşılmayacaktır.</h4>
              <div>
                <h4 className="text-darkblue col-md-6 p-0">Kayıtlı cep telefonu numaralarınız: </h4>
                <button className="btn border border-orange text-orange" onClick={() => this.setState({editPhone: null, isOpenPhoneModal: true})} title="Telefon Ekle">Telefon Ekle</button>
                <Link className="btn btn text-darkblue m-2 px-0" target='_blank' to={'/accountInfo'} title="Telefon numaralarınızı güncellemek veya silmek için tıklayınız."><b>Telefon numaralarınızı güncellemek veya silmek için tıklayınız.</b></Link>
              </div>
              <table className="col-md-6 table table-striped table-bordered">
                {this.state.memberPhones.length !== 0 &&
                  <thead>
                    <tr className="text-center text-darkblue">
                      <th>Telefon Tipi</th>
                      <th>Telefon Numarası</th>
                      <th>Telefon Doğrulama</th>
                    </tr>
                  </thead>
                }
                <tbody className="text-center text-darkblue">
                  {this.state.memberPhones.map((memberPhoneInfo,index) =>
                    <tr key={index}>
                      <td className="align-middle">{this.getPhoneTypes(memberPhoneInfo.phoneTypeId)}</td>
                      <td className="align-middle">{this.getPhoneNumber(memberPhoneInfo)}</td>
                      <td className="align-middle">{memberPhoneInfo.verified === 1 ? <strong className="text-green">Doğrulanmış Telefon</strong> : <button className="btn btn-darkblue" onClick={() => this.phoneValidateCodeOnClick(memberPhoneInfo)}>Telefonu Doğrula</button>}</td>
                    </tr>
                  )}
                  {this.state.memberPhones.length === 0 &&
                    <div className="col-12 d-block notFoundImg">
                      <div className="text-center">
                        <img className="m-4" src={Images.phoneNotFound}/>
                      </div>
                      <div className="text-center my-auto">
                        <h2 className="mb-1 mt-2"><b className="text-orange">Telefon Numarası Bulunamadı!</b></h2>
                        <h2 className="m-0 pb-4 text-darkblue">Sistemimize kayıtlı telefon numarası bulunamadı.</h2>
                      </div>
                    </div>
                  }
                </tbody>
              </table>
              <PhoneModal isOpen={this.state.isOpenPhoneModal} modalTitle="Telefon Ekle" modalDescription="Telefon bilgisi eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editPhone: null, isOpenPhoneModal: false})} saveOnClick={phoneInfo => this.saveMemberPhoneOnClick(phoneInfo)} />
              <div>
                  <img className="mb-4 mt-0" src={Images.phoneValidation}/>
              </div>
            </div>
          </div>
          {this.state.isOpenPhoneValidateModal && <PhoneValidateModal memberPhoneId={this.state.memberPhoneId} isOpen={this.state.isOpenPhoneValidateModal} modalTitle="Telefon Doğrulama" modalDescription="Numarayı doğrulamak için seçtiğiniz telefona doğrulama kodu gönderiniz !" onRequestClose={() => this.setState({isOpenPhoneValidateModal: false})} verifiedPhoneNumber={this.state.verifiedPhoneNumber} saveOnClick={validationCode => this.savePhoneValidateCodeOnClick(validationCode)} />}
        </div>
      </div>
  }
}

export default function PhoneValidation() {
  return (
    <PhoneValidationModule />
  )
}