import React from 'react';
import Modal from 'react-modal';
import swal from 'sweetalert';

import clientData from '../inc/clientData';
import config from '../inc/config';

export default class PhoneValidateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,

      counter: 60,
      isDisableCounting: true, 

      validationCode: '',
      showInput: false,

      memberPhoneId: this.props.memberPhoneId,
      verifiedPhoneNumber: this.props.verifiedPhoneNumber
    };
  }


  componentDidMount() {
    this.setInterval();
    console.log("PhoneValidateModal: componentDidMount()");
  }

  componentDidUpdate() {
    if(this.state.isDisableCounting && this.state.counter === 0) {
      this.setState({isDisableCounting: false, counter: 60})
      clearInterval(this.interval);
    }
  }

  componentWillUnmount() {
    console.log("PhoneValidateModal: componentWillUnmount()");
  }

  setInterval() {
    this.interval = setInterval(() => {
      if(this.state.isDisableCounting && this.state.counter > 0) {
        this.setState({counter: this.state.counter - 1})
      }
    }, 1000);
  }

  phoneValidateCodeOnClick = () => {
    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'phoneValidationCode/tr/' + this.state.memberPhoneId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Doğrulama kodu SMS olarak telefonunuza gönderilmiştir.',
            buttons: 'Tamam'
          }).then(() => this.setState({showInput: true}))
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  saveOnClick = () => {
    let infoMessage = '';
    if (!this.state.validationCode) {
      infoMessage = 'Doğrulama kodu boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.props.saveOnClick(parseInt(this.state.validationCode));
      this.setState({validationCode: ''});
    }
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveOnClick();
      event.preventDefault();
    }
  }
  
  sendSmsCodeOnClick = () => {
    this.phoneValidateCodeOnClick();
    this.setInterval();
    this.setState({isDisableCounting: true})
  }
  
  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)'
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
              </div>
              <div className="modal-body text-darkblue">
                <div className="d-block">
                  {this.state.showInput ? 
                   (
                      <div className="text-center">
                        <button className="btn btn-green btn-circle"><i className="fa fa-check"></i></button>
                        <h4>Lütfen telefonunuza SMS olarak gelen kodu giriniz !</h4>
                        <input className="form-control form-input mb-2 mt-2" onChange={(event) => this.setState({validationCode: event.target.value.replace(/[^0-9]/g, "")})} onKeyDown={this.handleKeyPress} placeholder="Doğrulama Kodu" value={this.state.validationCode}/>
                        <div className="d-flex flex-column">
                          <button className="btn btn-orange" onClick={this.saveOnClick}>Kodu Doğrula</button>
                          <strong className="mt-5 mb-1">Lütfen Bekleyiniz... {this.state.counter}</strong>
                          <button className="btn bg-gray" disabled={this.state.isDisableCounting} onClick={this.state.isDisableCounting ? null :  this.sendSmsCodeOnClick}><strong>Tekrar Sms Gönder</strong></button>
                        </div>
                      </div>
                    )
                    : 
                    (
                    <div>
                      <div className="text-center">
                        <i className="fa fa-mobile-phone fa-3x"></i>
                        <h3 className="text-center">Telefon Numarasını Doğrula</h3>
                        <input className="form-control form-input mb-4 mt-3" readOnly value={this.state.verifiedPhoneNumber}></input>
                        <button className="btn btn-darkblue text-white" onClick={this.phoneValidateCodeOnClick}>Doğrulama Kodunu SMS İle Gönder</button>
                      </div>
                    </div>
                    )
                  }
                </div>
              </div>
              <div className="modal-footer">
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}