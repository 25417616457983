import React from 'react';
import Modal from 'react-modal';
import swal from 'sweetalert';

export default class UpdateFavoriteSearchesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,

      title: this.props.defaultValue && this.props.defaultValue.title || '-'
    };
  }

  componentDidMount() {
    console.log("UpdateFavoriteSearchModal: componentDidMount()");
  } 

  componentDidUpdate(prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState(prevState => ({
        title: this.props.defaultValue.title
      }));
    }
  }

  componentWillUnmount() {
    console.log("UpdateFavoriteSearchModal: componentWillUnmount()");
  }

  saveOnClick = () => {
    let infoMessage = '';
    if (!this.state.title) {
      infoMessage = 'Arama adı boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.props.saveOnClick(this.state.title);
      this.setState({title: ''})
    }
  }
  
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveOnClick();
      // event.preventDefault();
    }
  }

  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)'
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
                <div className="d-block">
                  <div>
                    <strong>Arama Adı<strong className="text-danger ml-1">*</strong></strong>
                    <input className="form-control form-input mb-2 mt-1" onChange={event => this.setState({title: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Arama Adı" type="text" value={this.state.title}/>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-orange" onClick={this.saveOnClick}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}