import React from 'react';
import Modal from 'react-modal';
import {Navigate} from 'react-router-dom';
import swal from 'sweetalert';
import Select from 'react-select'
import {getCookie} from '../inc/cookies';
import config from '../inc/config';

export default class AssignConsultantModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      
      consultantInfo: {
        id: this.props.defaultValue && this.props.defaultValue.id || 0,
        fullName: this.props.defaultValue && this.props.defaultValue.firstname && this.props.defaultValue.lastname && this.props.defaultValue.firstname + ' ' + this.props.defaultValue.lastname || '',
      },

      consultants: []
    };
  }

  componentDidMount() {
    this.getConsultants();
    console.log("AssignConsultant: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("AssignConsultant: componentWillUnmount()");
  }

  getConsultants() {
    let sessionHash = getCookie('sessionHash');

    this.setState({isLoading: true});

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(config.restApi + 'consultants/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({isLoading: false, consultants: responseData.consultants});
        }
        break;
        default: {
          this.setState({isLoading: false});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => <Navigate to={'/home'} replace={true}></Navigate>);
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.saveOnClick();
      event.preventDefault();
    }
  }

  saveOnClick = () => {
    let infoMessage = '';
    if (!this.state.consultantInfo.id) {
      infoMessage = 'Danışman boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.props.saveOnClick(this.state.consultantInfo);
      this.setState({consultantInfo: {id: 0, fullName: ''}});
    }
  }

  selectedConsultantOnChange = (selectedConsultant) => {
    this.setState({consultantInfo: {...this.state.consultantInfo, id: selectedConsultant.value, fullName: selectedConsultant.label}})
  };

  render() {

    const selectedConsultantOptions = this.state.consultants.map((consultantInfo) => ({
      value: consultantInfo.id,
      label: `${consultantInfo.firstname} ${consultantInfo.lastname}`
    }))

    return (
      <div className="modal inmodal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)'
              }
            }}
          >
            {this.state.isLoading ?
              <div className="ibox-content sk-loading position-static">
                <div className="sk-spinner sk-spinner-fading-circle">
                  <div className="sk-circle1 sk-circle"></div>
                  <div className="sk-circle2 sk-circle"></div>
                  <div className="sk-circle3 sk-circle"></div>
                  <div className="sk-circle4 sk-circle"></div>
                  <div className="sk-circle5 sk-circle"></div>
                  <div className="sk-circle6 sk-circle"></div>
                  <div className="sk-circle7 sk-circle"></div>
                  <div className="sk-circle8 sk-circle"></div>
                  <div className="sk-circle9 sk-circle"></div>
                  <div className="sk-circle10 sk-circle"></div>
                  <div className="sk-circle11 sk-circle"></div>
                  <div className="sk-circle12 sk-circle"></div>
                </div>
              </div>
            :
              <>
                <div className="modal-header d-block text-center text-darkblue">
                  <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                  <h4 className="modal-title">{this.props.modalTitle}</h4>
                  <h5 className="font-normal">{this.props.modalDescription}</h5>
                </div>
                <div className="modal-body text-darkblue">
                  <strong>Danışman</strong>
                  <Select
                  className="form-input mb-2 mt-1 text-dark"
                  options={selectedConsultantOptions}
                  onChange={this.selectedConsultantOnChange}
                  onKeyDown={this.handleKeyPress}
                  value={selectedConsultantOptions.find((consultantInfo) => consultantInfo.value === this.state.consultantInfo.id)}
                  placeholder="Danışman"
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  styles={{
                    menuPortal: (provided) => ({
                      ...provided,
                      zIndex:3000
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 3000,
                    }),
                  }}
                  >
                  </Select>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-orange" onClick={this.saveOnClick}>Kaydet</button>
                </div>
              </>
            }
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}
