import React from 'react';
import Modal from 'react-modal';
import swal from 'sweetalert';

import {getCookie} from '../inc/cookies';
import config from '../inc/config';

export default class SelectConsultantModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      memberId: this.props.memberId,
      categoryId: 0,

      cities: [],
      towns: [],
      regions: [],
      
      cityId: 0,
      townId: 0,
      districtId: 0,
      quarterId: 0
    };
    this.handleCitySelect = this.handleCitySelect.bind(this);
    this.handleTownSelect = this.handleTownSelect.bind(this);
    this.handleRegionSelect = this.handleRegionSelect.bind(this);
    this.handleQuarterSelect = this.handleQuarterSelect.bind(this);
  }

  componentDidMount() {
    this.getCities()
    window.scrollTo(0, 0);
    console.log("SelectConsultantModal: componentDidMount()");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categoryId !== this.props.categoryId) {
      this.setState({categoryId: this.props.categoryId});
    }
  }

  componentWillUnmount() {
    console.log("SelectConsultantModal: componentWillUnmount()");
  }

  getCities() {

    const requestOptions = {
      method: 'GET',
    };

    fetch(config.restApi + 'cities/tr/'+ 1, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              this.setState({isLoading: false, cities: responseData.cities});
              break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
        },
        () =>
          swal({
            dangerMode: true,
            icon: "error",
            title: "Bağlantı Hatası",
            text: "Lütfen internet bağlantınızı kontrol ediniz.",
            buttons: "Tamam",
          })
      );
  }

  getTowns(cityId) {

    const requestOptions = {
      method: 'GET',
    };

    fetch(config.restApi + 'towns/tr/'+ cityId, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              this.setState({isLoading: false, towns: responseData.towns});
              break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
        },
        () =>
          swal({
            dangerMode: true,
            icon: "error",
            title: "Bağlantı Hatası",
            text: "Lütfen internet bağlantınızı kontrol ediniz.",
            buttons: "Tamam",
          })
      );
  }

  getRegions(townId) {

    const requestOptions = {
      method: 'GET',
    };

    fetch(config.restApi + 'regions/tr/'+ townId, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              this.setState({isLoading: false, regions: responseData.regions});
              break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
        },
        () =>
          swal({
            dangerMode: true,
            icon: "error",
            title: "Bağlantı Hatası",
            text: "Lütfen internet bağlantınızı kontrol ediniz.",
            buttons: "Tamam",
          })
      );
  }

  saveOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = "";

    if (!this.state.cityId) {
      infoMessage = "İl boş geçilemez !";
    } else if (!this.state.townId) {
      infoMessage = "İlçe boş geçilemez !";
    } else if (!this.state.districtId) {
      infoMessage = "Semt boş geçilemez !";
    } else if (!this.state.quarterId) {
      infoMessage = "Mahalle boş geçilemez !";
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: "info",
        title: "Eksik Bilgi",
        text: infoMessage,
        buttons: "Tamam",
      });
    } else {
      this.setState({ isLoading: true });

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({categoryId: this.props.categoryId, memberId: this.state.memberId, cityId: this.state.cityId, townId: this.state.townId, districtId: this.state.districtId, quarterId: this.state.quarterId})
      };
      console.log(requestOptions.body,"body")
      fetch(config.restApi + 'advert/tr', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isLoading: true});
            window.location.href= `/advertEdit?id=${responseData.advertId}`;
          }
          break;
          case 205: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => this.props.onRequestClose(false));
          }
          break;
          default: {
            this.setState({isLoading: true});
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  handleCitySelect(event) {
    const selectedCityId = event.target.value;
    this.setState({cityId: parseInt(selectedCityId), townId: 0, districtId: 0, quarterId: 0}, () => this.getTowns(selectedCityId));
  }

  handleTownSelect(event) {
    const selectedTownId = event.target.value;
    this.setState({ townId: parseInt(selectedTownId) }, () => this.getRegions(selectedTownId));
  }

  handleRegionSelect(event) {
    const selectedRegionId = event.target.value;
    this.setState({ districtId: parseInt(selectedRegionId)})
  }

  handleQuarterSelect(event) {
    const selectedQuarterId = event.target.value;
    this.setState({ quarterId: parseInt(selectedQuarterId)})
  }

  filterQuartersByRegion(regionId) {
    const selectedRegion = this.state.regions.find(region => region.id === regionId);
    return selectedRegion ? selectedRegion.quarters : [];
  }

  render() {
    const filteredQuarters = this.filterQuartersByRegion(this.state.districtId);
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)',
                zIndex: 1
              }
            }}
          >
            <div className={this.state.isLoading ? "ibox-content sk-loading no-padding" : "ibox-content no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">{this.props.modalTitle}</h4>
                <h5 className="font-normal">{this.props.modalDescription}</h5>
              </div>
              <div className="modal-body text-darkblue">
              <strong>il<strong className="text-danger ml-1">*</strong></strong>
                  <select className="form-control form-control-sm form-input mb-2 mt-1 b-r-sm" onChange={this.handleCitySelect} value={parseInt(this.state.cityId)}>
                    <option value="">Seçiniz</option>
                    {this.state.cities.map(cityInfo => <option key={cityInfo.id} value={cityInfo.id}>{cityInfo.name}</option>)}
                  </select>
                  <strong>İlçe<strong className="text-danger ml-1">*</strong></strong>
                  <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.handleTownSelect} value={this.state.townId}>
                    <option value="">Seçiniz</option>
                    {this.state.towns.map(townInfo => <option key={townInfo.id} value={townInfo.id}>{townInfo.name}</option>)}
                  </select>
                  <strong>Semt<strong className="text-danger ml-1">*</strong></strong>
                  <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.handleRegionSelect} value={this.state.districtId}>
                    <option value="">Seçiniz</option>
                    {this.state.regions.map(regionInfo => <option key={regionInfo.id} value={regionInfo.id}>{regionInfo.name}</option>)}
                  </select>
                  <strong>Mahalle<strong className="text-danger ml-1">*</strong></strong>
                  <select className="form-control form-control-sm form-input mb-2 mt-1" onChange={this.handleQuarterSelect} value={this.state.quarterId}>
                    <option value="">Seçiniz</option>
                    {filteredQuarters.map(quarterInfo => <option key={quarterInfo.id} value={quarterInfo.id}>{quarterInfo.name}</option>)}
                  </select>
                <div className="row">
                  <div className="col">
                    <div className="d-flex flex-column mt-4">
                      {this.props.consultantCompanies.map((consultantCompanyInfo) => 
                        <label className="font-bold" key={consultantCompanyInfo.id}><input checked={this.state.memberId === consultantCompanyInfo.id} onChange={() => this.setState({memberId: consultantCompanyInfo.id})} name="selectConsultant" type="radio" value="s" /> {consultantCompanyInfo.name}</label>)
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer"> 
                <button className="btn btn-orange" onClick={this.saveOnClick}>Tamam</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}
