import React from 'react';

import swal from 'sweetalert';

import clientData from '../../inc/clientData';
import config from '../../inc/config';

class ChangePasswordModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      isShowDefaultPassword: false,
      isShowNewPassword: false,
      isShowNewPasswordRepeat: false,

      currentPassword: '',
      newPassword: '',
      newPasswordRepeat: ''
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    console.log("ChangePassword: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("ChangePassword: componentWillUnmount()");
  }

  changePasswordOnClick = () => {

    let infoMessage = '';

    if (!this.state.currentPassword) {
      infoMessage = 'Mevcut şifre boş geçilemez !';
    } else if (!this.state.newPassword) {
      infoMessage = 'Yeni şifre boş geçilemez !';
    } else if (!this.state.newPasswordRepeat) {
      infoMessage = 'Yeni şifre tekrarı boş geçilemez !';
    } else if (this.state.newPassword !== this.state.newPasswordRepeat) {
      infoMessage = 'Yeni şifre ve şifre tekrarı aynı olmalıdır !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({currentPassword: this.state.currentPassword, newPassword: this.state.newPassword})
      };

      fetch(config.restApi + 'changePassword', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});
        switch (responseData.status) {
          case 200:
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Şifreniz başarıyla güncellendi.',
              buttons: 'Tamam'
            }).then(() => window.location.reload());
          break;
          case 205:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: "Hatalı şifre !",
              buttons: 'Tamam'
            });
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }
  
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.changePasswordOnClick();
    }
  }
  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col">
            <h3 className="text-darkblue">Şifre Değişikliği</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row">
          <div className="col-lg-6 my-2">
            <h5 className="text-darkblue"><strong>Mevcut Şifre<strong className="text-danger ml-1">*</strong></strong></h5>
            <input className="form-control form-input" onChange={(event) => this.setState({currentPassword: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Mevcut Şifre" type={this.state.isShowDefaultPassword ? "text" : "password"} value={this.state.currentPassword} />
            <span onClick={() => {this.setState({isShowDefaultPassword: !this.state.isShowDefaultPassword})}}>{this.state.isShowDefaultPassword ? <a className="isShowPassword fa fa-eye"></a> : <a className="isHidePassword fa fa-eye-slash"></a>}</span>
          </div>
        </div> 
        <div className="row">
         <div className="col-lg-6 my-2">
            <h5 className="text-darkblue"><strong>Yeni Şifre<strong className="text-danger ml-1">*</strong></strong></h5>
            <input className="form-control form-input" onChange={(event) => this.setState({newPassword: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Yeni Şifre" type={this.state.isShowNewPassword ? "text" : "password"} value={this.state.newPassword} />
            <span onClick={() => {this.setState({isShowNewPassword: !this.state.isShowNewPassword})}}>{this.state.isShowNewPassword ? <a className="isShowPassword fa fa-eye"></a> : <a className="isHidePassword fa fa-eye-slash"></a>}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 my-2">
            <h5 className="text-darkblue"><strong>Yeni Şifre Tekrar<strong className="text-danger ml-1">*</strong></strong></h5>
            <input className="form-control form-input" onChange={(event) => this.setState({newPasswordRepeat: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Yeni Şifre Tekrar" type={this.state.isShowNewPasswordRepeat ? "text" : "password"} value={this.state.newPasswordRepeat} />
            <span onClick={() => {this.setState({isShowNewPasswordRepeat: !this.state.isShowNewPasswordRepeat})}}>{this.state.isShowNewPasswordRepeat ? <a className="isShowPassword fa fa-eye"></a> : <a className="isHidePassword fa fa-eye-slash"></a>}</span>
          </div>
        </div>
        <div className="row d-flex justify-content-end pr-3 mt-5">
          <button className="btn bg-darkblue text-white font-bold" onClick={this.changePasswordOnClick}>Şifre Güncelle</button>
        </div>
      </div>
  }
}

export default function ChangePassword() {
  return (
    <ChangePasswordModule />
  )
}