import React from 'react';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import { Link } from 'react-router-dom';
import Images from '../../utils/Images';

class FavoriteSellersModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      sellers: [],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount() {
    this.getFavoriteSellers(this.state.currentPage);
    window.scrollTo(0, 0);
    console.log("FavoriteSellers: componentDidMount()");
  }
  
  componentWillUnmount() {
    console.log("FavoriteSellers: componentWillUnmount()");
  }

  getFavoriteSellers(currentPage) {
    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
  };

  const startIndex = 0;
  const endIndex = startIndex + this.state.limit;

  this.setState({isLoading: true})

  fetch(config.restApi + `favoriteSellers/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const currentSellers = responseData.sellers.slice(startIndex, endIndex);
          this.setState({isLoading: false, sellers: currentSellers, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteFavoriteSellerOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Satıcıyı favorilerinizden çıkarmak istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
        };

        fetch(config.restApi + 'favoriteSeller/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Satıcı favorilerden başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getFavoriteSellers());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getFavoriteSellers(this.state.currentPage));
    window.scrollTo(0, 0);
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col">
            <h3 className="text-darkblue">Favori Satıcılarım</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div>
          <Link className="btn btn-none-xs border btn-darkgray bg-orange-btn text-black mr-1" to={"/favoriteAdverts"}>Favori İlanlarım</Link>
          <Link className="btn btn-none-xs border btn-darkgray bg-orange-btn text-black mr-1" to={"/favoriteSearches"}>Favori Aramalarım</Link>
          <Link className="btn btn-none-xs border btn-darkgray bg-orange text-white" to={"/favoriteSellers"}>Favori Satıcılarım</Link>
        </div>
        <div className="d-flex justify-content-end">
          <div className="btn-open-xs btn border border-darkblue text-center mb-1 ">
            <span className="dropdown-toggle font-bold text-darkblue" data-toggle="dropdown"><i className="fa fa-ellipsis-v text-darkblue"></i></span>
            <ul className="dropdown-menu overflow-auto mr-3 mt-2">
              <li><Link className="dropdown-item font-bold" to={"/favoriteAdverts"}> Favori İlanlarım</Link></li>
              <li><Link className="dropdown-item font-bold" to={"/favoriteSearches"}> Favori Aramalarım</Link></li>
              <li><Link className="dropdown-item font-bold" to={"/favoriteSellers"}> Favori Satıcılarım</Link></li>
            </ul>
          </div>
        </div>
        <div className="row my-3">
          <div className="col">
            {this.state.sellers ? 
              <>
              <div className="table-responsive-lg mb-4">
                <table className="table table-bordered text-center">
                  {this.state.sellers.length !== 0 &&
                    <thead>
                      <tr className="text-darkblue">
                        <th>Satıcı Adı</th>
                        <th>Günlük Bildirimler</th>
                        <th>İşlem</th>
                      </tr>
                    </thead>
                  }
                  <tbody>
                    {
                      this.state.sellers.map((sellerInfo) =>
                        <tr key={sellerInfo.id}>  
                          <td className="align-middle"><a className="text-underline-orange text-orange" href="#" title={sellerInfo.storeName}>{sellerInfo.storeName || '-'}</a></td>
                          <td className="align-middle">Bildirimler Kapalı</td>
                          <td className="align-middle">
                            <div className="d-block d-md-flex justify-content-center">
                              <button className="btn border border-darkblue text-center" title="Sil" onClick={() => this.deleteFavoriteSellerOnClick(sellerInfo.id)}><i className="fa fa-trash text-darkblue"></i></button>
                            </div>
                          </td>
                        </tr>
                    )}
                    {this.state.sellers.length === 0 &&
                      <div className="col-12 d-block notFoundImg">
                        <div className="text-center">
                          <img className="mb-4 mt-0" src={Images.favoriteSellerNotFound}/>
                        </div>
                        <div className="text-center my-auto">
                          <h2 className="mb-1 mt-2"><b className="text-orange">Favori Listenizde Satıcı Bulunmadı!</b></h2>
                          <h2 className="m-0 pb-4 text-darkblue">İlgilendiğiniz satıcıyı favorilerinize eklemek istiyorsanız, ilan detay sayfasındaki "Satıcıyı Favorilerime Ekle" butonuna tıklayınız.</h2>
                        </div>
                      </div>
                    }
                  </tbody>
                </table>
              </div>
              {this.state.sellers.length !== 0 &&
              <ReactPaginate
                previousLabel={"Geri"}
                nextLabel={"İleri"}
                breakLabel={"..."}  
                pageCount={this.state.pageCount}
                marginPagesDisplayed={1}
                onPageChange={page => this.handlePageClick(page)}
                pageRangeDisplayed={2}
                containerClassName={'pagination'}
                activeClassName={'activePage'}
                forcePage={this.state.currentPage - 1}
              />
              }
              </>
              :
              <div className="p-2">
                <p><strong>Satıcıyı favorilerime nasıl eklerim?</strong></p>
                <p className="font-normal">Tüm yeni ilanlarından haberdar olmak istediğiniz satıcıyı, ilan detaylarını görüntülediğiniz sayfada bulunan <strong>Favori Satıcılarıma Ekle </strong>bağlantısını kullanarak favorilerinize ekleyebilirsiniz.</p>
              </div>
            }
          </div>
        </div> 
      </div>
  }
}

export default function FavoriteSellers() {
  return (
    <FavoriteSellersModule />
  )
}