import React from 'react';
import swal from 'sweetalert';
import {Link} from 'react-router-dom';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import Images from '../../utils/Images';
import {getCookie} from '../../inc/cookies';

class MailValidationModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      mailValidate: false,

      email: 0,
      identity: 0,
      phone:0
    };
  }

  componentDidMount() {
    this.getValidationStatus()
    window.scrollTo(0, 0);
    console.log("Consultants: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("Consultants: componentWillUnmount()");
  }

  getValidationStatus() {
    
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    this.setState({isLoading: true});

    fetch(config.restApi + 'validationStatus', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          this.setState({isLoading: false, email: responseData.email, identity: responseData.identity, phone: responseData.phone});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet basadadğlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  emailActivation = () => {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'}
    };
    
    this.setState({isLoading: true});

    fetch(config.restApi + 'emailActivation', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          this.setState({isLoading: false});
        }
        break;
        default: {
          this.setState({isLoading: false, mailValidate: false});
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="mt-2">
          <div className="row">
            <div className="col d-flex justify-content-between">
              <h3 className="text-darkblue">E-posta</h3>
            </div>
          </div>
          <div className="hr-line-dashed mt-0"></div>
          {this.state.email === 0 ?
            <div className="row">
              {this.state.mailValidate === false &&
              <div className="col my-2">
                <div className="table-responsive-lg">
                  <h4 className="text-darkblue col-md-6 p-0">İlanlarınız, favorileriniz ve mesajlarınızla ilgili önemli e-postaların size ulaştığından emin olmak ve hesap işlemleri için lütfen güncel e-posta adresinizi doğrulayın.</h4>
                  <h4 className="text-darkblue col-md-6 p-0">Kayıtlı e-posta adresiniz: <b>{clientData.email}</b></h4>
                  <button className="btn bg-orange text-white my-2" onClick={() => {this.emailActivation(); this.setState({mailValidate: true})}} title="E-posta ile Doğrula"><i className="fa fa-id-card mr-1"></i>E-posta ile Doğrula</button>
                  <div>
                      <img className="mb-4 mt-0" src={Images.mailValidation}/>
                  </div>
                </div>
              </div>
              }
              {this.state.mailValidate === true &&
              <div className="col my-2">
                <div className="table-responsive-lg">
                  <h4 className="text-darkblue col-md-6 p-0">E-posta doğrulaması için aktivasyon bağlantısı <b>{clientData.email}</b> adresine gönderildi. Aktivasyon işlemini tamamlamak için e-posta içerisindeki bağlantıya tıklayıp onay vermeniz gerekmektedir.</h4>
                </div>
              </div>
              }
            </div>
          :
            <div className="row">
              <div className="col my-2">
                <div className="table-responsive-lg">
                  <h4 className="col-md-6 border border-green text-green p-2"><i className="fa fa-check mr-1"></i>E-posta adresiniz <b>{clientData.email}</b> olarak başarıyla doğrulanmıştır.</h4>
                </div>
              </div>
            </div>
          }
        </div>
      </>
  }
}

export default function MailValidation() {
  return (
    <MailValidationModule />
  )
}