import React from 'react';

import swal from 'sweetalert';
import PhoneModal from '../../components/PhoneModal';
import PhoneValidateModal from '../../components/PhoneValidateModal';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import {getCookie} from '../../inc/cookies';
import Images from '../../utils/Images';

class AccountInfoModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenPhoneModal: false,
      isOpenPhoneValidateModal: false,

      firstname: '',
      lastname: '',
      username: '',
      
      defaultNumber: '',
      identityVerified: '',
      verifiedPhoneNumber: '',

      memberPhones: [],
      memberPhoneId: '',

      editPhone: null
    };
  }

  componentDidMount() {
    this.getPersonalInformation();
    window.scrollTo(0, 0);
    console.log("AccountInfo: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("AccountInfo: componentWillUnmount()");
  }

  getPersonalInformation() {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    fetch(config.restApi + 'personalInformation/tr' , requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const defaultPhoneNumber = responseData.personalInformation.memberPhones.find((memberPhoneInfo) => memberPhoneInfo.defaultSelected !== 0);
          const defaultNumber = defaultPhoneNumber ? defaultPhoneNumber.phoneNumber : '-';
          this.setState({
            isLoading: false,
            firstname: responseData.personalInformation.firstname,
            lastname: responseData.personalInformation.lastname,
            username: responseData.personalInformation.username,
            identityVerified: responseData.personalInformation.identityVerified,
            memberPhones: responseData.personalInformation.memberPhones,
            defaultNumber: defaultNumber
          });
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  contactSelectedOnChange(checkboxStatus, index, memberPhoneId) {
    let memberPhones = this.state.memberPhones;
    memberPhones[index].contactSelected = checkboxStatus;

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({contactSelected: checkboxStatus})
    };

    fetch(config.restApi + 'memberPhoneContactSelected/tr/' + memberPhoneId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          this.setState({memberPhones: memberPhones});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  defaultSelectedOnChange(defaultSelectedStatus, index, memberPhoneId) {
    let memberPhones = this.state.memberPhones;
    memberPhones[index].defaultSelected = defaultSelectedStatus;

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
    };

    fetch(config.restApi + 'memberPhoneDefaultSelected/tr/' + memberPhoneId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          this.setState({memberPhones: memberPhones});
          this.getPersonalInformation();
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.getPersonalInformation())
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }
  
  updateAccountInfoOnClick = () => {

    let infoMessage = '';

    if (!this.state.firstname) {
      infoMessage = 'İsim boş geçilemez !';
    } else if (!this.state.lastname) {
      infoMessage = 'Soyad boş geçilemez !';
    } else if (!this.state.username) {
      infoMessage = 'Kullanıcı adı boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({firstname: this.state.firstname, lastname: this.state.lastname, username: this.state.username})
      };

      fetch(config.restApi + 'personalInformation/tr', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});
        switch (responseData.status) {
          case 200:
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Kişisel bilgiler güncellendi.',
              buttons: 'Tamam'
            });
          break;
          case 205:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => this.getPersonalInformation());
          break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  saveMemberPhoneOnClick(phoneInfo) {
    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify(phoneInfo)
    };

    fetch(config.restApi + 'memberPhone/tr' , requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Telefon numarası başarıyla eklendi.',
            buttons: 'Tamam'
          }).then(() => this.getPersonalInformation(), this.setState({isOpenPhoneModal: false}));
        break;
        case 406:
          this.accountAuthorizationOnClick(phoneInfo);
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.setState({isOpenPhoneModal: false}), swal.close());
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  updateMemberPhoneOnClick(phoneInfo) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({phoneTypeId: phoneInfo.phoneTypeId, countryId: phoneInfo.countryId, phoneNumber: phoneInfo.phoneNumber})
    };

    fetch(config.restApi + 'memberPhone/tr/' + this.state.memberPhoneId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Telefon numarası başarıyla güncellendi.',
            buttons: 'Tamam'
          }).then(() => this.getPersonalInformation(), this.setState({isOpenPhoneModal: false}));
        break;
        case 406:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hesap Güvenliği',
            text: 'Bilgilerinizi güncelleyebilmek için şifrenizi girmeniz gerekmektedir.',
            buttons: 'Tamam'
          }).then(() => this.accountAuthorizationOnClick(phoneInfo));
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  accountAuthorizationOnClick() {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Hesap Güvenliği',
      text: 'Bilgilerinizi güncelleyebilmek için şifrenizi girmeniz gerekmektedir.',
      content: {
        element: 'input',
        attributes: {
          type: 'password',
          placeholder: 'Şifreniz'
        }
      },
      buttons: {
        confirm: 'Gönder',
        cancel: 'Vazgeç'
      }
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');
  
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json','Authorization': 'Bearer ' + sessionHash},
          body: JSON.stringify({password: value})
        };
  
        fetch(config.restApi + 'login', requestOptions)
          .then(response => response.json())
          .then(responseData => {
            switch (responseData.status) {
              case 200:
              break;
              case 404:
                swal({
                  dangerMode: true,
                  icon: 'warning',
                  title: 'Yanlış Şifre',
                  text: 'Girdiğiniz şifre yanlış. Lütfen tekrar deneyin.',
                  buttons: 'Tamam'
                }).then(() => this.accountAuthorizationOnClick());
                break;
              default:
                swal({
                  dangerMode: true,
                  icon: 'warning',
                  title: 'Hata',
                  text: responseData.message,
                  buttons: 'Tamam'
                });
                break;
            }
          }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  deleteMemberPhoneOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Telefonu silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {

        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
        };

        fetch(config.restApi + 'memberPhone/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200:
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Telefon başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getPersonalInformation());
            break;
            case 406:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hesap Yetkilendirme',
                text: 'Hesabınızı yetkilendirmek için şifrenizi girin.',
                buttons: 'Tamam'
              }).then(() => this.accountAuthorizationOnClick());
            break;
            default:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  phoneValidateCodeOnClick(memberPhoneInfo) {
    this.setState({isOpenPhoneValidateModal: true, memberPhoneId: memberPhoneInfo.id, verifiedPhoneNumber: memberPhoneInfo.phoneNumber});
  }

  getPhoneTypes(id) {
    if(id === 1) {
      return 'Cep Telefonu'
    } else if(id === 2) {
      return 'Sabit Telefon'
    } else if(id === 3) {
      return 'Fax'
    } 
    return '-'
  }

  savePhoneValidateCodeOnClick(validationCode) {
    const requestOptions = {
      method: 'PUT',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({validationCode: validationCode})
    };

    fetch(config.restApi + 'phoneValidationCode/tr/' + this.state.memberPhoneId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Telefon numaranız doğrulanmıştır.',
            buttons: 'Tamam'
          }).then(() => this.setState({isOpenPhoneValidateModal: false}), this.getPersonalInformation())
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getPhoneNumber(memberPhoneInfo) {
    let phoneNumber = memberPhoneInfo.phoneNumber.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
    let phoneMasking = `(${phoneNumber[1]}) ${phoneNumber[2]} ${phoneNumber[3]} ${phoneNumber[4]}`;
    return phoneMasking;
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.updateAccountInfoOnClick();
      event.preventDefault();
    }
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <h3 className="text-darkblue">Kişisel Bilgilerim</h3>   
            <div className="d-none">{this.state.identityVerified === 1 ? <h4 className="text-green pointer" title="Doğrulanmış Kimlik"> <i className="fa fa-check-square"></i> Doğrulanmış Hesap</h4> : <h4 className="text-darkblue font-bold pointer" title="Doğrulanmamış Kimlik"> <i className="fa fa-exclamation-triangle"></i> Doğrulanmamış Hesap</h4>}</div>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row">
          <div className="col-md-6 my-2">
            <h5 className="text-darkblue"><strong>Adı<strong className="text-danger ml-1">*</strong></strong></h5>
            <input className="form-control form-input" onChange={event => this.setState({firstname: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Ad" type="text" value={this.state.firstname} />
          </div>
          <div className="col-md-6 my-2">
            <h5 className="text-darkblue"><strong>Soyad<strong className="text-danger ml-1">*</strong></strong></h5>
            <input className="form-control form-input" onChange={event => this.setState({lastname: event.target.value.replace(/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g, '')})} onKeyDown={this.handleKeyPress} placeholder="Soyad" type="text" value={this.state.lastname} />
          </div>
          <div className="col-md-6 my-2">
            <h5 className="text-darkblue"><strong>Kullanıcı Adı<strong className="text-danger ml-1">*</strong></strong></h5>
            <input className="form-control form-input" onChange={event => this.setState({username: event.target.value})} onKeyDown={this.handleKeyPress} placeholder="Kullanıcı Adı" type="text" value={this.state.username} />
          </div>
          <div className="col-md-6 my-2">
            <h5 className="text-darkblue"><strong>Varsayılan Numara</strong></h5>
            <input className="form-control form-input" placeholder="Varsayılan Numara" readOnly type="text" value={this.state.defaultNumber ? this.phoneFormat(this.state.defaultNumber) : '-'}/>
          </div>
        </div>  
        <br></br>
        <div className="d-flex justify-content-between">
          <h4 className="font-bold text-darkblue">Telefon Bilgileri</h4>
          <div>
            <button className="btn border border-orange" onClick={() => this.setState({editPhone: null, isOpenPhoneModal: true})} title="Telefon Ekle"><i className="fa fa-plus text-orange"></i></button>
          </div>
        </div>
        <div className="hr-line-dashed"></div>
        <div className="overflow-auto">
          <table className="table table-striped table-bordered">
          {this.state.memberPhones.length !== 0 &&
            <thead>
              <tr className="text-center text-darkblue">
                <th>Telefon Tipi</th>
                <th>Telefon Numarası</th>
                <th>Varsayılan</th>
                <th>İletişim Numarası</th>
                <th>Telefon Doğrulama</th>
                <th>İşlem</th>
              </tr>
            </thead>
          }
          <tbody className="text-center text-darkblue">
            {this.state.memberPhones.map((memberPhoneInfo,index) =>
              <tr key={index}>
                <td className="align-middle">{this.getPhoneTypes(memberPhoneInfo.phoneTypeId)}</td>
                <td className="align-middle">{this.getPhoneNumber(memberPhoneInfo)}</td>
                <td className="align-middle"><input checked={memberPhoneInfo.defaultSelected} onChange={() => this.defaultSelectedOnChange(!memberPhoneInfo.defaultSelected ? 1 : 0, index, memberPhoneInfo.id)} type="checkbox" /></td>
                <td className="align-middle"><input checked={memberPhoneInfo.contactSelected} onChange={() => this.contactSelectedOnChange(!memberPhoneInfo.contactSelected ? 1 : 0, index, memberPhoneInfo.id)} type="checkbox" /></td>
                <td className="align-middle">{memberPhoneInfo.verified === 1 ? <strong className="text-green">Doğrulanmış Telefon</strong> : <button className="btn btn-darkblue" onClick={() => this.phoneValidateCodeOnClick(memberPhoneInfo)}>Telefonu Doğrula</button>}</td>
                <td className="align-middle" style={{minWidth: 98.5}}>
                  <button className="btn border border-orange m-1" onClick={() => this.setState({editPhone: memberPhoneInfo, isOpenPhoneModal: true, memberPhoneId: memberPhoneInfo.id})} title="Düzenle"><i className="fa fa-pencil text-orange"></i></button>
                  <button className="btn border border-darkblue" onClick={() => this.deleteMemberPhoneOnClick(memberPhoneInfo.id)} title="Sil"><i className="fa fa-trash text-darkblue"></i></button>
                </td>
               </tr>
            )}
            {this.state.memberPhones.length === 0 &&
              <div className="col-12 d-block notFoundImg">
                <div className="text-center">
                  <img className="m-4" src={Images.phoneNotFound}/>
                </div>
                <div className="text-center my-auto">
                  <h2 className="mb-1 mt-2"><b className="text-orange">Telefon Numarası Bulunamadı!</b></h2>
                  <h2 className="m-0 pb-4 text-darkblue">Sistemimize kayıtlı telefon numarası bulunamadı.</h2>
                </div>
              </div>
            }
          </tbody>
          </table>
          {this.state.isOpenPhoneModal && 
          (this.state.editPhone !== null ? (
            <PhoneModal
              defaultValue={this.state.editPhone}
              isOpen={this.state.isOpenPhoneModal}
              modalTitle="Telefon Düzenle"
              modalDescription="Telefon bilgisini düzenlemek için aşağıdaki alanları doldurunuz !"
              onRequestClose={() => this.setState({ isOpenPhoneModal: false })}
              saveOnClick={phoneInfo => this.updateMemberPhoneOnClick(phoneInfo)}
            />
            )
            : (<PhoneModal isOpen={this.state.isOpenPhoneModal} modalTitle="Telefon Ekle" modalDescription="Telefon bilgisi eklemek için aşağıdaki alanları doldurunuz !" onRequestClose={() => this.setState({editPhone: null, isOpenPhoneModal: false})} saveOnClick={phoneInfo => this.saveMemberPhoneOnClick(phoneInfo)} />)
          )}
          {this.state.isOpenPhoneValidateModal && <PhoneValidateModal memberPhoneId={this.state.memberPhoneId} isOpen={this.state.isOpenPhoneValidateModal} modalTitle="Telefon Doğrulama" modalDescription="Numarayı doğrulamak için seçtiğiniz telefona doğrulama kodu gönderiniz !" onRequestClose={() => this.setState({isOpenPhoneValidateModal: false})} verifiedPhoneNumber={this.state.verifiedPhoneNumber} saveOnClick={validationCode => this.savePhoneValidateCodeOnClick(validationCode)} />}
        </div>
        <div className="row d-flex justify-content-end pr-3 mt-5">
          <button className="btn bg-darkblue text-white font-bold" onClick={this.updateAccountInfoOnClick}>Kaydet</button>
        </div>
      </div>
  }
}

export default function AccountInfo() {
  return (
    <AccountInfoModule />
  )
}