import React from 'react';

class ProductsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    console.log("Products: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("Products: componentWillUnmount()");
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col text-left">
            <h3>Ürünlerim</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row mt-4">
          <div className="col">
            Eğer müşteri temsilcisi tarafından store oluşturulmuş ise; müşteri, temsilci ile uğraşmadan istediği paketi alabilir.olta bayrak vs gibi ürün satışı yapılabilecek.
          </div>
        </div>
      </div>
  }
}

export default function Products() {
  return (
    <ProductsModule />
  )
}