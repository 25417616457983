import React from 'react';
import Modal from 'react-modal';
import swal from 'sweetalert';

import {getCookie} from '../inc/cookies';
import config from '../inc/config';

export default class AddedConsultantModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: true,
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    console.log("AddAdvertNoteModal: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("AddAdvertNoteModal: componentWillUnmount()");
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.saveOnClick();
    }
  }

  saveNoteOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    let infoMessage = '';
    
    if (!this.props.comment) {
      infoMessage = 'açıklama boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isCompleted: false});

      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({comment: this.props.comment})
      };

      fetch(config.restApi + 'advertComment/tr/' + this.props.id, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isCompleted: true, comment: ''});

        switch (responseData.status) {
          case 200: {
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Not ekleme işlemi başarıyla güncellendi.',
              buttons: 'Tamam'
            }).then(() => this.props.onCompleted());
          }
          break;
          default: {
            this.setState({isCompleted: true});
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  handlePut = (event) => {
    const newComment = event.target.value;
    this.props.updateComment(newComment);
  }  

  render() {
    return (
      <div className="modal inmodal">
        <div className="modal-dialog">
          <div className="modal-content animated fadeIn">
          <Modal
            isOpen={this.props.isOpen}
            onRequestClose={this.props.onRequestClose}
            style={{
              content : {
                position : 'null',
                top : 'null',
                left : 'null',
                right : 'null',
                bottom : 'null',
                border : 'none',
                background : '#fff',
                padding : 'null',
                maxWidth : '50%',
                minWidth : '350px'
              },
              overlay : {
                position : 'fixed',
                top : 0,
                left : 0,
                right : 0,
                bottom : 0,
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                backgroundColor : 'rgba(0,0,0,00.1)'
              }
            }}
          >
            <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
              <div className="sk-spinner sk-spinner-fading-circle">
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
              </div>
              <div className="modal-header d-block text-center text-darkblue">
                <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                <h4 className="modal-title">Not Ekle</h4>
                <h5 className="font-normal"></h5>
              </div>
              <div className="modal-body text-darkblue">
              <div className="row">
                  <div className="col">
                    <strong>Açıklama<strong className="text-danger ml-1">*</strong></strong>
                    <textarea className="form-control form-control-sm form-input mb-2 mt-1" onChange={(e) => {this.handlePut(e)}} placeholder="Not giriniz.." type="text" value={this.props.comment} />
                  </div>
                </div>
              </div>
              <div className="modal-footer"> 
                <button className="btn btn-orange" onClick={this.saveNoteOnClick}>Kaydet</button>
              </div>
            </div>
          </Modal>
          </div>
        </div>
      </div>
    )
  }
}
