import React from 'react';
import swal from 'sweetalert';
import {Link, useNavigate} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {getCookie} from '../../inc/cookies';

import SelectConsultantModal from '../../components/SelectConsultantModal';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import Images from '../../utils/Images';
import AddAdvertNoteModal from '../../components/AddAdvertNoteModal';
import {isoToDateTime, priceFormat, slugify} from '../../inc/helper';

class ActiveAdvertsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenSelectConsultantModal: false,
      isOpenAdvertNoteModal: false,
      id: 0,
      comment: '',

      adverts: [],
      advertStatus: 0,
      consultantCompanies: [],
      categoryId: 0,

      memberId: clientData.id,

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
    this.advertUpdateDateOnClick = this.advertUpdateDateOnClick.bind(this);
    this.getAdverts = this.getAdverts.bind(this);
  }

  componentDidMount() {
    this.getAdverts(this.state.currentPage);
    window.scrollTo(0, 0);
    console.log("ActiveAdverts: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("ActiveAdverts: componentWillUnmount()");
  }

  // getAdverts(currentPage) {
  //   const requestOptions = {
  //   method: 'GET',
  //   headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
  //  };

  // const startIndex = 0;
  // const endIndex = startIndex + this.state.limit;

  // fetch(config.restApi + `myAdverts/tr?filterAdvertStatus=${2}&limit=${this.state.limit}&page=${currentPage}`, requestOptions)
  //   .then(response => response.json())
  //   .then(responseData => {
  //     switch(responseData.status) {
  //       case 200:
  //         const currentAdverts = responseData.adverts.slice(startIndex, endIndex);
  //         this.setState({isLoading: false, adverts: currentAdverts, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
  //       break;
  //       default:
  //         swal({
  //           dangerMode: true,
  //           icon: 'warning',
  //           title: 'Hay Aksi',
  //           text: responseData.message,
  //           buttons: 'Tamam'
  //         }).then(() => this.props.navigate('/'));
  //       break;
  //     }
  //   }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  // }

  getAdverts = (currentPage) => {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    this.setState({isLoading: true})

    fetch(config.restApi + `myAdverts/tr?filterAdvertStatus=${2}&limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentAdverts = responseData.adverts.slice(startIndex, endIndex);
          this.setState({adverts: currentAdverts, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'consultantCompanies/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({consultantCompanies: [{id: clientData.id, name: 'Kendi Hesabım için yayınla'}, ...responseData.consultantCompanies]});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

  }

  getPerson(memberId, publishedBy, addedBy) {
    if(memberId === publishedBy) {
      return <span>* Kendisi yayına aldı.</span>
    } else if(memberId !== addedBy && addedBy === publishedBy) {
      return <span>* Danışman yayına aldı.</span>
    } else if(memberId !== addedBy && addedBy !== publishedBy) {
      return <span>* Sistem yayına aldı.</span>
    } else {
      return '- '
    }
  }

  deleteAdvertOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'İlanı silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
        };

        fetch(config.restApi + 'advert/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'İlan favroilerden başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getAdverts());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getAdverts(this.state.currentPage));
    window.scrollTo(0, 0);
  }

  takeDownAdvertOnClick(advertId){
    swal({
      dangerMode: true,
      icon: 'info',
      title: 'Onayla',
      text: 'Yayından kaldırmak istediğinize emin misiniz ?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then((value) => {
      if (value) {
        const requestOptions = {
          method: 'PUT',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
          body: JSON.stringify({advertStatus: 4})
        };

        fetch(config.restApi + 'advertStatus/tr/' + advertId, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200:
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Başarılı',
                text: 'İlan başarıyla yayından kaldırıldı.',
                buttons: 'Tamam'
              }).then(() => this.getAdverts(this.state.currentPage));
            break;
            default:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

      }
    });
  }

  advertUpdateDateOnClick(){
    swal({
      dangerMode: true,
      icon: 'info',
      title: 'Onayla',
      text: 'İlanlarınızın yayınlama tarihini güncellemek istediğinize emin misiniz ?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then((value) => {
      if (value) {
        const requestOptions = {
          method: 'PUT',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'}
        };

        fetch(config.restApi + 'advertRenew/tr', requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200:
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Başarılı',
                text: 'İlanların yayınlama tarihi başarıyla güncellendi.',
                buttons: 'Tamam'
              }).then(() => this.getAdverts(this.state.currentPage));
            break;
            default:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

      }
    });
  }

  postInTheSameCategoryOnClick = (categoryId) => {
    this.setState({categoryId: categoryId});
    this.state.consultantCompanies.length === 1 ? this.saveOnClick() : this.setState({isOpenSelectConsultantModal: true})
  }

  saveOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({categoryId: this.state.categoryId, memberId: this.state.memberId})
    };
    
    this.setState({isLoading: true});

    fetch(config.restApi + 'advert/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          this.setState({isLoading: false});
          window.location.href= `/advertEdit?id=${responseData.advertId}`;
        }
        
        break;
        default: {
          this.setState({isLoading: false});
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  deleteAdvertNoteOnClick = (id) => {
    let sessionHash = getCookie('sessionHash');
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Notunuzu silmek istediğinize emin misiniz?',
      buttons: { confirm: 'Evet', cancel: 'Vazgeç' }
    }).then(value => {
      if (value) {
        this.setState({ isCompleted: false, comment: '' });
  
        const requestOptions = {
          method: 'PUT',
          headers: { 'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json' },
          body: JSON.stringify({ comment: '' })
        };
  
        fetch(config.restApi + 'advertComment/tr/' + id, requestOptions)
          .then(response => response.json())
          .then(responseData => {
            this.setState({ isCompleted: true });
  
            switch (responseData.status) {
              case 200: {
                swal({
                  dangerMode: true,
                  icon: 'success',
                  title: 'Tamamlandı',
                  text: 'Notunuz başarıyla silindi.',
                  buttons: 'Tamam'
                }).then(() => this.getAdverts());
              }
                break;
              default: {
                swal({
                  dangerMode: true,
                  icon: 'warning',
                  title: 'Hay Aksi',
                  text: responseData.message,
                  buttons: 'Tamam'
                });
              }
                break;
            }
          }, () => swal({ dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam' }));
      }
    });
  }

  getAdvertStatus(id, title) {
    if(id === 1) {
      return <strong className="text-warning">Onay Bekliyor</strong>
    } else if(id === 2) {
      return <strong className="text-green">Yayında</strong>
    } else if(id === 3) {
      return <strong className="text-orange">Reddedildi</strong>
    } else if(id === 4) {
      if (title) {
        return <strong className="text-darkblue">Pasif</strong>;
      } else {
        return <strong className="text-darkblue">Taslak</strong>;
      }
    } else {
      return '-'
    }
  }

  handleEdit = (ID, comment) => {
    this.setState({id: ID, comment: comment, isOpenAdvertNoteModal: true})
  }

  onCompleted = () => {
    this.getAdverts();
    this.setState({isOpenAdvertNoteModal: false});
  }

  updateComment = (newComment) => {
    this.setState({ comment: newComment });
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col">
            <div className="d-flex justify-content-between">
              <h3 className="text-darkblue">Yayında Olan İlanlarım</h3>
              <strong className="text-orange d-flex align-items-center">Yayında Olan Toplam İlan Sayısı: {this.state.resultCount}</strong>
            </div>
            <div className="hr-line-dashed mt-0"></div>
            <div>
              <Link className="btn btn-none border btn-darkgray bg-orange-btn text-black mr-1" to={"/adverts"}>Tüm İlanlarım</Link>
              <Link className="btn btn-none border btn-darkgray bg-orange text-white mr-1" to={"/activeAdverts"}>Yayında Olan İlanlarım</Link>
              <Link className="btn btn-none border btn-darkgray bg-orange-btn text-black mr-1" to={"/passiveAdverts"}>Yayında Olmayan İlanlarım</Link>
              <Link className="btn btn-none border btn-darkgray bg-orange-btn text-black mr-1" to={"/pendingAdverts"}>Onay Bekleyen İlanlarım</Link>
              <button className="btn btn-none border btn-darkgray bg-orange-btn text-black mt-1 m-md-0" onClick={this.advertUpdateDateOnClick} title="İlan Tarihlerini Güncelle">Yayında olan ilanların tarihini güncelle</button>
            </div>
            <div className="d-flex justify-content-end">
              <div className="btn-open btn border border-darkblue text-center mb-1 ">
                <span className="dropdown-toggle font-bold text-darkblue" data-toggle="dropdown"><i className="fa fa-ellipsis-v text-darkblue"></i></span>
                <ul className="dropdown-menu overflow-auto mr-3 mt-2">
                  <li><Link className="dropdown-item font-bold" to={"/adverts"}> Tüm İlanlarım</Link></li>
                  <li><Link className="dropdown-item font-bold" to={"/activeAdverts"}> Yayında Olan İlanlarım</Link></li>
                  <li><Link className="dropdown-item font-bold" to={"/passiveAdverts"}> Yayında Olmayan İlanlarım</Link></li>
                  <li><Link className="dropdown-item font-bold" to={"/pendingAdverts"}> Onay Bekleyen İlanlarım</Link></li>
                  <li><button className="dropdown-item font-bold" onClick={this.advertUpdateDateOnClick} title="İlan Tarihlerini Güncelle">Yayında olan ilanların tarihini güncelle</button></li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col my-2">
                {this.state.adverts.map((advertInfo) => 
                  <div className="col-12 border br-darkblue bg-gray2 br-3 p-3 mb-2" key={advertInfo.id}>
                    <div className="row">
                      <div className="col-12 mb-2">
                        <div className="d-flex flex-column text-center text-sm-center text-md-left">
                          {advertInfo.title ? <strong className="text-darkblue"><Link className="text-darkblue" target='_blank' to={`https://${config.host}/ilan/${slugify(advertInfo.title)}-${advertInfo.id}/detay`}>{advertInfo.title}</Link></strong> : <strong className="text-darkblue">İlan başlığı girilmedi</strong>}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
                        <div className="d-flex flex-column text-center">
                          <div className="mt-1 d-flex justify-content-center align-items-center"> {advertInfo.photos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{ height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${advertInfo.photos[0].path}`}/> : <i className="fa fa-camera d-flex justify-content-center align-items-center" style={{height: 100, /* maxWidth:200, */ objectFit:"contain", overflow: "visible", fontSize: 100, color: '#d2d2d2' }}></i>}</div>
                          <div className="text-center font-bold text-orange"><span>İlan No: #{advertInfo.id}</span></div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 p-0 mb-2">
                        <div className="d-flex flex-column text-center text-md-left">
                          <h5 className="font-bold text-darkblue">Son Yayınlanma Tarihi: {advertInfo.publishedDate ? isoToDateTime(advertInfo.publishedDate) : '-'}</h5>
                          <h5 className="font-bold text-darkblue">Yayından Kaldırılma Tarihi: {advertInfo.removedDate ? isoToDateTime(advertInfo.removedDate) : '-'}</h5>
                          <h5 className="font-bold text-darkblue">{advertInfo.townName + ' / ' + advertInfo.cityName}</h5>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-12 mb-2">
                        <div className="d-flex flex-column">
                          <h3 className="text-center">{priceFormat(advertInfo.price)} {advertInfo.currencySymbol}</h3>
                          <div className="d-flex justify-content-center">
                            <i className="fa fa-heart text-danger mr-2" title="Favori Sayısı"> {advertInfo.favoriteCount}</i>
                            <i className="fa fa-eye text-green mr-2" title="Görüntüleme Sayısı"> {advertInfo.views}</i>
                            <i className="fa fa-envelope text-warning mr-2" title="Mesaj Sayısı"> {advertInfo.messageCount}</i>
                            <i className="fa fa-bell text-success mr-2 d-none" title="Bildirimi okumak için tıklayın">!</i>
                          </div>
                          <div className="text-center p-1">{this.getPerson(advertInfo.memberId, advertInfo.publishedBy, advertInfo.addedBy)}</div>
                          <div className="text-center p-1">{advertInfo.comment || '-'}  {advertInfo.comment !== '' ? <i className='fa fa-trash ml-2 pointer' title="sil" onClick={() => this.deleteAdvertNoteOnClick(advertInfo.id)}></i>: ''}</div>
                          <div className="text-center">{this.getAdvertStatus(advertInfo.advertStatus, advertInfo.title)}</div>
                          <div className="mt-5 text-xxl-center text-md-center text-sm-center grid">
                          {advertInfo.title && (advertInfo.advertStatus === 2 || advertInfo.advertStatus === 4) ? <Link className="btn btn-w-m border border-green font-bold text-green mr-xl-1 mr-lg-1 mr-sm-2 mr-xs-2 mb-2 mb-md-2" target='_blank' to={`https://kredi.${config.host}/creditProviders?id=${advertInfo.id}`}>Kredi Başvurusu</Link> : <Link className="btn btn-w-m border font-bold mr-xl-1 mr-lg-1 mr-sm-2 mr-xs-2 mb-2 mb-md-2" title="Sadece yayında veya pasif durumdaki ilan için başvuru yapabilirsiniz.">Kredi Başvurusu</Link>}
                            <button className="btn btn-w-m border border-orange font-bold text-orange mr-xl-1 mr-lg-1  mr-xs-2 mr-sm-2 mb-2 mb-md-2" style={{minWidth:128.5}} onClick={() => this.takeDownAdvertOnClick(advertInfo.id)}>Yayından Kaldır</button>
                            <div className="btn btn-w-m border border-darkblue font-bold mb-2 mr-lg-1 mr-sm-2" style={{minWidth:128.5}}>
                              <span className="dropdown-toggle font-bold text-darkblue" data-toggle="dropdown">İşlemler</span>
                              <ul className="dropdown-menu overflow-auto mr-3 mt-2">
                                <li><Link className="dropdown-item pl-1 font-bold" to={'/advertEdit?id=' + advertInfo.id}><i className="fa fa-edit text-darkblue m-r-xs"></i> Düzenle</Link></li>
                                <li><Link className="dropdown-item pl-1 font-bold" onClick={() => this.deleteAdvertOnClick(advertInfo.id)}><i className="fa fa-trash text-darkblue m-r-xs"></i> Sil</Link></li>
                                <li><Link className="dropdown-item pl-1 font-bold" to={"/selectConsultant?id=" + advertInfo.categoryId}><i className="fa fa-plus text-darkblue m-r-xs"></i> Aynı Kategoride İlan Ver</Link></li>
                                <li><Link className="dropdown-item pl-1 font-bold" onClick={() => {this.updateComment(advertInfo.comment); this.handleEdit(advertInfo.id, advertInfo.comment)}}><i className="fa fa-plus text-darkblue m-r-xs"></i> İlana Not Ekle</Link></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <AddAdvertNoteModal comment={this.state.comment} updateComment={this.updateComment} id={this.state.id} isOpen={this.state.isOpenAdvertNoteModal} modalTitle="İlan Sahibi Seçimi" modalDescription="Lütfen ilanı yayınlamak istediğiniz kişiyi seçiniz !" onCompleted={this.onCompleted} onRequestClose={() => this.setState({isOpenAdvertNoteModal: false})} />
                <SelectConsultantModal consultantCompanies={this.state.consultantCompanies} categoryId={this.state.categoryId} memberId={this.state.memberId} isOpen={this.state.isOpenSelectConsultantModal} modalTitle="İlan Sahibi Seçimi" modalDescription="Lütfen ilanı yayınlamak istediğiniz kişiyi seçiniz !" onRequestClose={() => this.setState({isOpenSelectConsultantModal: false})} />
                {this.state.adverts.length === 0 && 
                  <div className="col-12 d-block notFoundImg">
                    <div className="text-center">
                      <img className="m-4" src={Images.advertNotFound}/>
                    </div>
                    <div className="text-center my-auto">
                      <h2 className="mb-1 mt-2"><b className="text-orange">İlan Bulunamadı!</b></h2>
                      <h2 className="m-0 pb-4 text-darkblue">Şu anda yayında olan ilanınız bulunmamaktadır.</h2>
                    </div>
                  </div>
                }
              </div>
            </div>          
            {this.state.adverts.length !== 0 &&
            <div className="d-flex justify-content-sm-end justify-content-start pl-1 pl-sm-0 mt-4">
              <ReactPaginate
                previousLabel={"Geri"}
                nextLabel={"İleri"}
                breakLabel={"..."}  
                pageCount={this.state.pageCount}
                marginPagesDisplayed={1}
                onPageChange={page => this.handlePageClick(page)}
                pageRangeDisplayed={2}
                containerClassName={'pagination'}
                activeClassName={'activePage'}
                forcePage={this.state.currentPage - 1}
              />
            </div>
            }
          </div>
        </div>
      </div>
    }
  }

export default function ActiveAdverts() {
  return (
    <ActiveAdvertsModule navigate={useNavigate()} />
  )
}