import React from 'react';
import {Link} from 'react-router-dom';

import Images from '../utils/Images';

import clientData from '../inc/clientData';
import config from '../inc/config';
import {isoToDateTime, slugify} from '../inc/helper';
import ReactPaginate from 'react-paginate';

class AccountSummaryModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      accountSummary: {},

      adverts: [],
      searches: [],
      products: [],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount() {
    this.getAccountSummary(this.state.currentPage);
    window.scrollTo(0, 0);
    console.log("AccountSummary: ComponentDidMount()");
  }

  componentWillUnmount() {
    console.log("AccountSummary: componentWillUnmount()");
  }

  getAccountSummary(currentPage) {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    let errorMessages = [];
    let remaining = 4;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + `accountSummary/tr?limit=${this.state.limit}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({accountSummary: responseData.accountSummary});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + `accountProducts/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentproducts = responseData.products.slice(startIndex, endIndex);
          this.setState({products: currentproducts, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + `favoriteAdverts/tr?filterAdvertStatus=2&limit=${this.state.limit}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({adverts: responseData.adverts});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + `favoriteSearches/tr?limit=${this.state.limit}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          this.setState({searches: responseData.searches});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getAccountSummary(this.state.currentPage))
  }

  getSearchLink(params) {
    let filter = [];

    params.categoryId && filter.push('categoryId=' + params.categoryId);
    params.addedBy && filter.push('addedBy=' + params.addedBy);
    params.memberId && filter.push('memberId=' + params.memberId);

    if (Array.isArray(params.cityIds)) {
      params.cityIds.length > 0 && filter.push('cityIds=' + params.cityIds.join(','));
    } else {
      params.cityIds && filter.push('cityIds=' + params.cityIds);
    }
    if (Array.isArray(params.cityIds)) {
      params.townIds.length > 0 && filter.push('townIds=' + params.townIds.join(','));
    } else {
      params.townIds && filter.push('townIds=' + params.townIds);
    }
    if (Array.isArray(params.cityIds)) {
      params.quarterIds.length > 0 && filter.push('quarterIds=' + params.quarterIds.join(','));
    } else {
      params.quarterIds && filter.push('quarterIds=' + params.quarterIds);
    }

    if (params.maxPrice || params.minPrice) {
      filter.push('currencyPrice=' + params.currencyPrice)
    }

    params.maxPrice && filter.push('maxPrice=' + params.maxPrice);
    params.minPrice && filter.push('minPrice=' + params.minPrice);

    params.lastUpdate && filter.push('lastUpdate=' + params.lastUpdate);
    params.hasPhoto && filter.push('hasPhoto=1');

    if (params.searchText) {
      filter.push('searchText=' + params.searchText);
      filter.push('searchType=' + params.searchType);
    }

    params.attributes?.length > 0 && filter.push('attributes=' + params.attributes.join(','));

    let fields = params.fields && Object.entries(params.fields).map(([fieldId, values]) => `${fieldId}:${values.join(',')}`).join(';');
    fields && filter.push('fields=' + fields);

    let rangeFields = params.rangeFields && Object.entries(params.rangeFields).map(([fieldId, values]) => `${fieldId}:${values.join('-')}`).join(';');
    rangeFields && filter.push('rangeFields=' + rangeFields);

    params.sort && filter.push('sort=' + params.sort);

    if (filter.length > 0) {
      return `https://www.${config.host}/adverts?${filter.join('&')}`;
    } else {
      return `https://www.${config.host}/adverts`;
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col">
            <h3 className="text-darkblue">Hesap Özeti</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row mt-4">
          <div className="col-lg-4 mb-2 mb-lg-0">
            <Link to={"/activeAdverts"}>
              <div className="h-100 p-lg border br-26 bg-gray">
                <i className="fa fa-list-ul fa-3x text-darkblue"></i>
                <div className="mt-3 text-darkblue">
                  <h3><strong>Yayında Olan İlanlar Sayısı</strong></h3>
                  <h2 className="mt-3"><strong>{this.state.accountSummary.publishedAdvert}</strong></h2>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 mb-2 mb-lg-0">
            <Link to={"/favoriteAdverts"}>
              <div className="h-100 p-lg border br-26 bg-gray">
                <i className="fa fa-heart fa-3x text-darkblue"></i>
                <div className="mt-3 text-darkblue">
                  <h3><strong>Favorilerdeki İlan Sayısı</strong></h3>
                  <h2 className="mt-3"><strong>{this.state.accountSummary.favoriteAdvert}</strong></h2>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 mb-2 mb-lg-0">
            <Link to={"/messages"}>
              <div className="h-100 p-lg border br-26 bg-gray">
                <i className="fa fa-envelope fa-3x text-darkblue"></i>
                <div className="mt-3 text-darkblue">
                  <h3><strong>İlanlarınıza Gelen Mesaj Sayısı</strong></h3>
                  <h2 className="mt-3"><strong>{this.state.accountSummary.unseenMessage}</strong></h2>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="d-flex">
              <h3 className="font-bold text-darkblue">Paket Detaylarım</h3>
            </div>
            <div className="table-responsive-lg">
              <table className="table table-striped table-bordered text-center">
                <thead>
                  <tr className="text-darkblue">
                    <th>Ürün Adı</th>
                    <th>Ürün Hakkı</th>
                    <th>Ürün Başlangıç Tarihi</th>
                    <th>Ürün Bitiş Tarihi</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.products.map((productInfo) =>
                    <tr key={productInfo.id}>  
                      <td className="align-middle">{productInfo.name}</td>
                      <td className="align-middle">{productInfo.quantity} Adet</td>
                      <td className="align-middle">{isoToDateTime(productInfo.periodStart)}</td>
                      <td className="align-middle">{(isoToDateTime(productInfo.periodEnd))}</td>
                    </tr>
                    )
                  }
                  {this.state.products.length === 0 && <tr><td className="align-middle text-darkblue p-3" colSpan="4">Ürün Detayı Bulunamadı !</td></tr>}
                </tbody>
              </table>
              {this.state.products.length !== 0 &&
              <ReactPaginate
                previousLabel={"Geri"}
                nextLabel={"İleri"}
                breakLabel={"..."}  
                pageCount={this.state.pageCount}
                marginPagesDisplayed={1}
                onPageChange={page => this.handlePageClick(page)}
                pageRangeDisplayed={2}
                containerClassName={'pagination'}
                activeClassName={'activePage'}
              />
              }
            </div>
          </div>
        </div>
        <div className="row mt-4 d-block d-lg-flex">
          <div className="col-12 col-lg-6">
            <div className="d-flex justify-content-between">
              <h3 className="font-bold text-darkblue">Favori İlanlarım</h3>
              <h4 className="font-bold text-underline-darkblue pointer"><Link className="text-darkblue" to={'/favoriteAdverts'}>Tüm Favori İlanlar</Link></h4>
            </div>
            <div className="table-responsive-lg" style={{height: "calc(50vh - 90px)", overflowY: "auto"}}>
              <table className="table table-bordered text-center">
              <thead>
                <tr className="text-darkblue">
                  <th>İlan Görseli</th>
                  <th>İlan Başlığı</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.adverts.map((advertInfo) => 
                  <tr key={advertInfo.id}>
                    <td className="align-middle">
                      {advertInfo.photos.length > 0 ? <Link to={'/advertEdit?id=' + advertInfo.id}><img alt="İlan Görseli" className="w-md-50" style={{height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${advertInfo.photos[0].path}`}/></Link>
                      : <Link to={'/advertEdit?id=' + advertInfo.id}><i className="fa fa-camera d-flex align-items-center justify-content-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2'}}></i></Link>}<br /><strong className="text-darkblue font-bold"> İlan No: #{advertInfo.id}</strong>
                    </td>
                    {advertInfo.title ? <td className="align-middle"><Link className="text-orange text-underline-orange" target='_blank' to={`https://${config.host}/ilan/${slugify(advertInfo.title)}-${advertInfo.id}/detay`}>{advertInfo.title}</Link></td>: <td className="align-middle"><span className="d-flex justify-content-center align-items-center text-orange">-</span></td>}
                  </tr>
                )}
                {this.state.adverts.length === 0 && <tr><td className="align-middle text-darkblue p-3" colSpan="8">Favori İlan Bulunamadı !</td></tr>}
               </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="d-flex justify-content-between">
              <h3 className="font-bold text-darkblue">Favori Aramalarım</h3>
              <h4 className="font-bold text-underline-darkblue pointer"><Link className="text-darkblue" to={'/favoriteSearches'}>Tüm Favori Aramalar</Link></h4>
            </div>
            <div className="table-responsive-lg" style={{height: "calc(50vh - 90px)", overflowY: "auto"}}>
              <table className="table table-bordered text-center">
              <thead>
                  <tr className="text-darkblue">
                    <th>Arama Adı</th>
                    <th>Günlük Bildirimler</th>
                  </tr>
                </thead>
                <tbody>
                {
                  this.state.searches.map((searchInfo) =>
                    <tr key={searchInfo.id}>  
                      <td className="align-middle">
                        <a className="text-orange text-underline-orange" target='_blank' href={this.getSearchLink(JSON.parse(searchInfo.params || '{}'))}>{searchInfo.title}</a>
                      </td>
                      <td className="align-middle">Bildirimler Kapalı</td>
                    </tr>
                  )
                }
                {this.state.searches.length === 0 && <tr><td className="align-middle text-darkblue p-3" colSpan="2">Favori Arama Bulunamadı !</td></tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function AccountSummary() {
  return (
    <AccountSummaryModule />
  )
}