import React from 'react';
import {useNavigate} from 'react-router-dom';

import swal from 'sweetalert';

import config from '../../inc/config';
import {getCookie} from '../../inc/cookies';

class AcoountCancellationModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    console.log("AccountCancellation: componentDidMount()");
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    console.log("AccountCancellation: componentWillUnmount()");
  }

  accountCancellationOnClick = () => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Hesabınızı kalıcı olarak silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Evet', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        this.sendPasswordForAccountDelete();
      }
    });
  }
  
  sendPasswordForAccountDelete() {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Hesap Silme',
      text: 'Hesabınızı kalıcı olarak silmek için şifrenizi girin.',
      content: {
        element: 'input',
        attributes: {
          type: 'password',
          placeholder: 'Şifreniz'
        }
      },
      buttons: {
        confirm: 'Gönder',
        cancel: 'Vazgeç'
      }
    }).then(value => {
      if (value) {
        let sessionHash = getCookie('sessionHash');
  
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json','Authorization': 'Bearer ' + sessionHash},
          body: JSON.stringify({password: value})
        };
  
        fetch(config.restApi + 'login', requestOptions)
          .then(response => response.json())
          .then(responseData => {
            switch (responseData.status) {
              case 200:
                this.deleteAccount();
                break;
              case 404:
                swal({
                  dangerMode: true,
                  icon: 'warning',
                  title: 'Yanlış Şifre',
                  text: 'Girdiğiniz şifre yanlış. Lütfen tekrar deneyin !',
                  buttons: 'Tamam'
                }).then(() => this.sendPasswordForAccountDelete());
                break;
              default:
                swal({
                  dangerMode: true,
                  icon: 'warning',
                  title: 'Hata',
                  text: responseData.message,
                  buttons: 'Tamam'
                });
                break;
            }
          }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }
  
  deleteAccount() {
    let sessionHash = getCookie('sessionHash');
  
    const requestOptions = {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json','Authorization': 'Bearer ' + sessionHash}
    };
  
    fetch(config.restApi + 'deleteAccount', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200:
            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Hesabınız başarıyla silindi.',
              buttons: 'Tamam'
            }).then(() => window.location.replace('/'));
          break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hata',
              text: responseData.message,
              buttons: 'Tamam'
            });
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }
  
  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col text-left">
            <h3 className="text-darkblue">Hesap iptali</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row">
          <div className="col my-2">
            <div className="bg-gray2 p-3 text-darkblue font-bold">
              <p>Hesabınızı iptal etmek istemenize üzüldük...</p>
              <p>
                Sizi satisgaranti.com ailesinin bir parçası olarak görmeye devam etmek istediğimiz için görüşleriniz bizim için çok değerli. Hesabınız ile ilgili yaşadığınız bir sorun veya sormak istedikleriniz varsa bize
                Destek Merkezi üzerinden ulaşabilirsiniz.
              </p>
              <p>satisgaranti.com hesabınızı iptal ederseniz;</p>
              <ul>
                <li>Hesabınız yeniden aktif hale getirilemeyecektir.</li>
                <li>Yayında olan ilanlarınızın tamamı yayından kalkacaktır.</li>
                <li>Doping kullandığınız ilanınız varsa ücret iadesi yapılamayacaktır.</li>
                <li>Kimlik Numaranızı ve Cebinden Onaylı telefon numaranızı başka bir satisgaranti.com hesabınıza taşıyabilirsiniz.</li> 
                <li>E-posta adresinizin yeni bir hesapta 10 yıl boyunca kullanılamayacağını da üzülerek hatırlatmak isteriz.</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-end pr-3 mt-5">
          <button className="btn bg-darkblue text-white font-bold" onClick={this.accountCancellationOnClick}>Hesabımı İptal Etmek İstiyorum</button>
        </div>
      </div>
  }
}

export default function AcoountCancellation() {
  return (
    <AcoountCancellationModule navigate={useNavigate()} />
  )
}